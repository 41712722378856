import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { editProject, projectsReducerValues } from '../../../Projects/reducer';
import { useTranslation } from 'react-i18next';
import { Controls, Title, drodownStyles, pickerStyles } from './styles';
import { useParams } from 'react-router-dom';
import { DayOfWeek, Dropdown, Stack } from '@fluentui/react';

import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { DatePicker, IDropdownOption } from '@fluentui/react';
import { commonTools } from '../../../../tools/commonTools';
import { useAppDispatch } from '../../../../store';
import { log } from 'console';
import { DateTime } from 'luxon';
import { IProject } from '../../../Projects/interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { locationsReducerValues, patchLocation } from '../../../Locations/Locations.reducer';
import useDayPickerStrings from '../../../../constants/useDayPickerStrings';
import { currenciesMap } from '../../../../constants/currencies';
import { IProjectLocation } from '../../../Locations/Locations.interfaces';

const READ_ONLY_COLOR = 'rgb(233,237,241)';

export const LocationManagement: React.FC = () => {
    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const DAY_PICKER_STRINGS = useDayPickerStrings();

    const {
        urls: { PROJECT_LOCATIONS_URL },
        token,
        locales,
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [name, setName] = useState('');
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState<Date | undefined>(undefined);
    const [currencyCode, setCurrencyCode] = useState(0);
    const [fpcRecalcDays, setFpcRecalcDays] = useState<number | null>(null);

    useEffect(() => {
        activeProjectLocation && setName(() => activeProjectLocation.name);
        activeProjectLocation && setFpcRecalcDays(() => activeProjectLocation.fpc_recalc_days);
        activeProjectLocation && setDateFrom(() => DateTime.fromISO(activeProjectLocation.date_from).toJSDate());
        activeProjectLocation?.date_to &&
            setDateTo(() =>
                DateTime.fromISO(activeProjectLocation.date_to ?? '').isValid
                    ? DateTime.fromISO(activeProjectLocation.date_to ?? '').toJSDate()
                    : undefined,
            );
        activeProjectLocation && setCurrencyCode(() => activeProjectLocation.currency_code);
    }, [activeProjectLocation]);

    const onNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setName(newValue || '');
    };

    const onFpcRecalcDaysChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        if (!newValue) {
            setFpcRecalcDays(null);
        } else if (isFinite(Number(newValue))) {
            setFpcRecalcDays(newValue === '' ? null : parseInt(newValue, 10));
        } else {
        }
    };

    const onEditClick = () => {
        const data: Partial<IProjectLocation> = {
            name,
            date_from: DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd'),
            date_to: dateTo ? DateTime.fromJSDate(dateTo).toFormat('yyyy-MM-dd') : null,
            currency_code: currencyCode,
            fpc_recalc_days: fpcRecalcDays,
        };
        activeProjectLocation &&
            dispatch(
                patchLocation({
                    url: PROJECT_LOCATIONS_URL,
                    token,
                    locationId: String(activeProjectLocation.id),
                    data,
                }),
            );
    };

    const onParseDateFromString = React.useCallback(
        (mode: string) =>
            (newValue: string): Date => {
                return commonTools.getDateFromString({ value: dateFrom, newValue });
            },
        [dateFrom],
    );

    const onChangeCurrency = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        option && setCurrencyCode(() => Number(option.key));
    };
    const buttonDisabled = !name || !dateFrom || !currencyCode;

    const currencyOptions = Object.entries(locales?.currencies ?? {}).map(([key, value]) => ({
        key,
        text: `${key}: ${value}`,
    }));

    if (activeProjectLocation) {
        const title: string = activeProjectLocation.name;
        return (
            <>
                <Title>{`${t('Info')}: ${title}`}</Title>
                <Controls style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                        <TextField
                            style={{ backgroundColor: READ_ONLY_COLOR }}
                            label={'Pl ID'}
                            value={activeProjectLocation.id.toString()}
                            readOnly
                        />
                        <TextField
                            style={{ minWidth: 200, backgroundColor: READ_ONLY_COLOR }}
                            label={t('Access key')}
                            value={activeProjectLocation.access_key}
                            readOnly
                        />
                        <TextField
                            style={{ backgroundColor: READ_ONLY_COLOR }}
                            label={t('Timezone')}
                            value={activeProjectLocation.timezone}
                            readOnly
                        />
                        <TextField
                            style={{ backgroundColor: READ_ONLY_COLOR }}
                            label={t('Latitude, longitude')}
                            value={`${activeProjectLocation.latitude}, ${activeProjectLocation.longitude}`}
                            readOnly
                        />
                    </div>
                    <TextField
                        style={{ minWidth: 500, backgroundColor: READ_ONLY_COLOR }}
                        multiline
                        label={t('Address')}
                        value={activeProjectLocation.address}
                        readOnly
                    />
                </Controls>
                <br />
                <br />
                <Title>{`${t('Edit')}`}</Title>
                <Controls>
                    <TextField
                        style={{ minWidth: 300 }}
                        label={t('Location name')}
                        value={name}
                        onChange={onNameChange}
                        required
                    />
                    <DatePicker
                        label={t('Date from')}
                        isRequired={true}
                        allowTextInput={true}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DAY_PICKER_STRINGS}
                        value={dateFrom}
                        onSelectDate={setDateFrom as (date?: Date | undefined | null) => void}
                        formatDate={commonTools.onFormatDate}
                        parseDateFromString={onParseDateFromString('date_from')}
                        style={{ marginBottom: -5 }}
                    />
                    <DatePicker
                        label={t('Date to')}
                        allowTextInput={true}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DAY_PICKER_STRINGS}
                        value={dateTo}
                        onSelectDate={setDateTo as (date?: Date | undefined | null) => void}
                        formatDate={commonTools.onFormatDate}
                        parseDateFromString={onParseDateFromString('date_from')}
                        style={{ marginBottom: -5 }}
                    />

                    <Dropdown
                        style={{ width: '200px' }}
                        label={t('Currency code')}
                        required
                        selectedKey={String(currencyCode)}
                        onChange={onChangeCurrency}
                        options={currencyOptions}
                        styles={drodownStyles}
                    />
                    <TextField
                        label={t('FPC recount days')}
                        value={fpcRecalcDays?.toString() || ''}
                        onChange={onFpcRecalcDaysChange}
                        required
                    />

                    <PrimaryButton
                        text={t('Edit')}
                        onClick={onEditClick}
                        allowDisabledFocus
                        disabled={buttonDisabled}
                    />
                </Controls>
            </>
        );
    } else {
        return null;
    }
};
