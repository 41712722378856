import { IDropdownOption } from '@fluentui/react';
import { IVersionsData } from './../../../Layers/layers.interfaces';
import { IExtendedPlan, IGeneralSettings } from '../../interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { AppThunk, RootState } from './store';
import { commonTools } from '../../monitoringLayers';
import { getRequest } from './api';
import { IPlan, IPoint } from '../../interfaces';

import { IPCCReducer, IPCC, ISensor, IRelation, IContextMenu } from './interfaces';
import { IHotAddObject } from '../../interfaces';
import { testData } from './testData';

export const initialState: IPCCReducer = {
    activeToolId: null,
    alert: { show: false, text: '', type: 'warning' },

    selectedSensorId: null,
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString(),
    // dateFrom: new Date(Date.now() - 86400000).toISOString(),
    dateTo: new Date().toISOString(),
    date: new Date().toISOString(),
    versionsData: null,
    activePlId: null,
    generalSettings: {} as IGeneralSettings,
    selectedPassPointId: null,
    createdAt: undefined,
    pcIpoints: null,
    sensors: null,
    maxMeasures: 1,
    isLayerVisible: false,
    relations: null,
    currentPlanData: null,
    showLabels: false,
    layerModal: { show: false },
    layerAlias: 'sensors_monitoring_layer',
    spinner: false,
    anotherLocationNames: { markers: [] },
    contextMenu: { show: false },
    hotAddObject: null,
};

export const PCCReducer = createSlice({
    name: 'PCCReducer',
    initialState,
    reducers: {
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string | null>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeActivePlId: (state, action: PayloadAction<number | null>) => {
            state.activePlId = action.payload;
        },

        /**
         * Запись dateFrom
         */
        storeDateFrom: (state, action: PayloadAction<Date>) => {
            state.dateFrom = action.payload.toISOString();
        },

        /**
         * Запись dateTo
         */
        storeDateTo: (state, action: PayloadAction<Date>) => {
            state.dateTo = action.payload.toISOString();
        },

        /**
         * Запись date
         */
        storeDate: (state, action: PayloadAction<Date>) => {
            state.date = action.payload.toISOString();
            const year = action.payload.getFullYear();
            const month = action.payload.getMonth();
            const day = action.payload.getDate();

            const dateFrom = new Date(year, month, day, 0, 0, 0).toISOString();
            let dateTo = new Date(year, month, day, 23, 59, 59).toISOString();
            if (commonTools.isToday(action.payload)) {
                dateTo = new Date().toISOString();
            }
            state.dateFrom = dateFrom;
            state.dateTo = dateTo;
        },

        /**
         * запись инфы о версиях.
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Переключает контекстное меню
         */
        toggleContextMenu: (state, action: PayloadAction<IContextMenu>) => {
            state.contextMenu = action.payload as IContextMenu;
        },

        /**
         * Выбор объекта
         */
        selectSensor: (state, action: PayloadAction<number | undefined>) => {
            if (action.payload === undefined) {
                state.selectedSensorId = null;
            } else {
                state.selectedSensorId = action.payload;
            }
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof IPCC; newValue: string | undefined; id: string }>,
        ) => {
            const { id, key, newValue } = action.payload;
            const pcIpoints = cloneDeep(state.pcIpoints) || [];
            const newObjects = pcIpoints.map((item) => {
                if (item.frontId !== id) {
                    return item;
                } else {
                    let value;
                    switch (key) {
                        default:
                            value = newValue;
                            break;
                    }
                    return { ...item, [key]: value };
                }
            });
            state.pcIpoints = newObjects;
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IPCC>>) => {
            state.pcIpoints = action.payload;
        },

        /**
         * Запись в стор сенсоров
         */
        storeSensors: (state, action: PayloadAction<Array<IRelation>>) => {
            const { pcIpoints } = cloneDeep(state);
            const relations = action.payload;

            if (pcIpoints && relations) {
                const sensors: Array<ISensor> = [];
                pcIpoints.forEach((item) => {
                    const sensor = relations.filter((rel) => rel.ipoint_marker === item.marker)[0];
                    if (sensor) {
                        sensors.push({ ...sensor, centerPoint: item.centerPoint } as ISensor);
                    }
                });

                const sortedSensors = sensors.map((sensor) => {
                    const sortedVals = sensor.values.sort((a, b) => new Date(a.t).getTime() - new Date(b.t).getTime());
                    return { ...sensor, values: sortedVals };
                });

                const maxMeasures = Math.max(...sensors.map((item) => item.values.length));

                state.maxMeasures = maxMeasures;
                state.sensors = sensors;
                state.relations = relations;
            }
        },

        /**
         * Обновление сенсоров при смене этажа
         */
        updateSensors: (state, action: PayloadAction<Array<IPCC>>) => {
            const { relations } = cloneDeep(state);
            const pcIpoints = action.payload;

            if (pcIpoints && relations) {
                const sensors: Array<ISensor> = [];
                pcIpoints.forEach((item) => {
                    const sensor = relations.filter((rel) => rel.ipoint_marker === item.marker)[0];
                    if (sensor) {
                        sensors.push({ ...sensor, centerPoint: item.centerPoint } as ISensor);
                    }
                });

                const maxMeasures = Math.max(...sensors.map((item) => item.values.length));
                state.maxMeasures = maxMeasures;

                state.sensors = sensors;
            }
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Переключение показа модалки
         */
        toggleLayerModal: (state, action: PayloadAction<{ show: boolean }>) => {
            state.layerModal = action.payload;
        },

        /**
         * Сохранение в стор всех имен из других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];

            action?.payload.forEach((item: { data?: any }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string }) => {
                    markers.push(obj.marker);
                });
            });
            const anotherLocationNames = { markers };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Запись в стор видимости слоя
         */
        storeVisible: (state, action: PayloadAction<boolean>) => {
            state.isLayerVisible = action.payload;
        },

        /**
         * Переключатель спиннера
         */
        toggleSpinner: (state, action: PayloadAction<boolean>) => {
            state.spinner = action.payload;
        },
        /**
         * Включение/выключение алерта.
         */
        toggleAlert: (state, action: PayloadAction<{ show: boolean; text: string }>) => {
            state.alert = !action.payload.show ? { show: false, text: '', type: 'warning' } : action.payload;
        },

        /**
         * Сброс сенсоров
         */
        resetSensors: (state, action: PayloadAction<undefined>) => {
            state.layerModal = { show: false };
            state.sensors = null;
        },
    },
});

export const {
    toggleAlert,
    storeDateTo,
    storeDateFrom,
    storeDate,
    toggleTool,
    toggleContextMenu,
    selectSensor,
    changeObjectParams,
    storeInitialObjects,
    storeGeneralSettings,
    toggleShowLabels,
    toggleLayerModal,
    storeCurrentPlanData,
    storeVisible,
    storeVersionsData,
    storeActivePlId,
    updateSensors,
    storeAnotherLocationNames,
    storeHotAddObject,
    storeSensors,
    resetSensors,
    toggleSpinner,
} = PCCReducer.actions;

export const reducerValues = (state: RootState) => state.reducer;

export default PCCReducer.reducer;

export const fetchRelations = (): AppThunk => (dispatch, getState) => {
    const { generalSettings, activePlId, dateFrom, dateTo } = cloneDeep(getState().reducer);
    if (!activePlId || !activePlId || !generalSettings?.urls?.FPC_SENSOR_STATUS_URL) return;
    const url = `${generalSettings?.urls.FPC_SENSOR_STATUS_URL}${activePlId}?date_from=${dateFrom}&date_to=${dateTo}&time_freq=15min`;
    dispatch(toggleSpinner(true));
    getRequest({
        url,
        dispatch,
        token: generalSettings.token,
    }).then((data: any) => {
        dispatch(storeSensors(data));
        dispatch(toggleSpinner(false));
    });

    // dispatch(storeSensors(testData));
};
