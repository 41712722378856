import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProduct } from '../../Accounts.interfaces';
import { accountsReducerValues, fetchUsers, storeLocalProductIds, storeOnEdit } from '../../Accounts.reducer';
import { dropdownStyles, OptionBubble, OptionsWrapper, ProductCellWrapper } from './styles';
import { useAppDispatch } from '../../../../../../store';
import { PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { generalReducerValues, toggleAlert } from '../../../../../../General.reducer';
import { deleteRequest, getRequest, postRequest } from '../../../../../../tools/api';
import axios from 'axios';
import { IProject2Product } from '../../../../../Projects/interfaces';

const ProductsCell: React.FC<{
    products: IProduct[];
    assignedProductIds: number[];
    accountId: number;
    projectId: string;
}> = ({ products, assignedProductIds, accountId, projectId }) => {
    const dispatch = useAppDispatch();
    const {
        urls: { USER_2_PRODUCT_URL, PROJECT_2_PRODUCT_URL },
        token,
    } = useSelector(generalReducerValues);
    const { user2product, accounts, localProductIds, onEdit } = useSelector(accountsReducerValues);
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(true);
    const project2Product = useQuery({
        queryKey: ['project-2-product', projectId],
        queryFn: () => {
            const url = `${PROJECT_2_PRODUCT_URL}?project_id=${projectId}`;
            return getRequest({ url, token }).then((data) => data as IProject2Product[]);
        },
        staleTime: 15 * 60 * 1000,
    });

    const options: IDropdownOption[] = products.map((item: IProduct) => {
        const disabled = !project2Product?.data?.map((ll) => Number(ll.product_id)).includes(Number(item.id));
        return { key: item.id, text: item.name, disabled };
    });

    useEffect(() => {
        dispatch(storeLocalProductIds({ accountId, ids: [...assignedProductIds] }));
    }, [accountId, assignedProductIds]);

    const mutation = useMutation({
        mutationFn: ({ IdsToDelete, IdsToPost }: { IdsToDelete: number[]; IdsToPost: number[] }) => {
            const url = `${USER_2_PRODUCT_URL}`;

            const deleteRequests = IdsToDelete.map((id) => {
                const bindingId = user2product.find((item) => item.user_id === accountId && item.product_id === id)?.id;
                return deleteRequest({ url: `${url}${bindingId}/`, token });
            });
            const postRequests = IdsToPost.map((id) => {
                const data = { product_id: id, user_id: accountId };
                return postRequest({ url, token, data });
            });

            return axios.all([...postRequests, ...deleteRequests]).then(() => {});
        },
    });

    const onChange = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        index?: number | undefined,
    ): void => {
        if (option?.key) {
            option.selected &&
                dispatch(storeLocalProductIds({ accountId, ids: [...localProductIds[accountId], Number(option.key)] }));
            !option.selected &&
                dispatch(
                    storeLocalProductIds({
                        accountId,
                        ids: localProductIds[accountId].filter((id) => id !== Number(option.key)),
                    }),
                );
        }
    };

    const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
        const result = options?.map((option) => {
            return <OptionBubble key={option.key}>{option.text}</OptionBubble>;
        });
        return <OptionsWrapper>{result}</OptionsWrapper>;
    };

    const onEditProductsClick = () => {
        const IdsToDelete = assignedProductIds?.filter((id) => !localProductIds[accountId].includes(Number(id)));
        const IdsToPost = localProductIds[accountId]?.filter((id) => !assignedProductIds?.includes(Number(id)));
        mutation.mutate(
            { IdsToDelete, IdsToPost },
            {
                onSuccess: () => {
                    const userName = accounts.find((item) => item.id === accountId)?.username;
                    dispatch(
                        toggleAlert({
                            id: 'Products changed for user',
                            show: true,
                            type: 'success',
                            text: `${t('Products changed for user')} ${userName}`,
                            lifeTime: 3000,
                        }),
                    );
                    dispatch(fetchUsers(projectId));
                },
                onError: () => {
                    dispatch(
                        toggleAlert({
                            id: 'Error with saving',
                            show: true,
                            type: 'warning',
                            text: t('Error with saving'),
                            lifeTime: 3000,
                        }),
                    );
                    dispatch(fetchUsers(projectId));
                },
            },
        );
    };

    useEffect(() => {
        if (
            mutation.isPending ||
            (onEdit && onEdit?.type !== 'products') ||
            (onEdit && onEdit.accountId !== accountId) ||
            (assignedProductIds.every((id) => localProductIds[accountId]?.includes(id)) &&
                assignedProductIds.length === localProductIds[accountId]?.length)
        ) {
            setDisabled(() => true);
        } else {
            setDisabled(() => false);
        }
    }, [accountId, assignedProductIds, localProductIds, mutation.isPending, onEdit]);

    useEffect(() => {
        if (!disabled) {
            dispatch(storeOnEdit({ type: 'products', accountId }));
        } else {
            dispatch(storeOnEdit(null));
        }
    }, [accountId, assignedProductIds, disabled, dispatch]);

    const dropdownDisabled =
        (Boolean(onEdit) && onEdit?.type !== 'products') || (Boolean(onEdit) && onEdit?.accountId !== accountId);

    return (
        <ProductCellWrapper>
            <Dropdown
                placeholder={t('Select products')}
                selectedKeys={localProductIds[accountId]}
                disabled={dropdownDisabled}
                onChange={onChange}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onRenderTitle={onRenderTitle}
            />
            <PrimaryButton
                text={mutation.isPending ? t('Saving...') : t('Save')}
                disabled={disabled}
                onClick={onEditProductsClick}
            />
        </ProductCellWrapper>
    );
};

export default ProductsCell;
