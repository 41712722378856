import { IGetAccordionDataArgs } from './getAccordionData.interfaces';

/**
 * Функция получения данных для аккордиона.
 * ЭТА ФУНКЦИЯ БУДЕТ ПЕРЕДЕЛАНА
 * @param {ILayer} item - Слой.
 * @param {IInitialLayers} initialLayers - Начальные данные о слоях.
 */
export const getAccordionData = ({
    item,
    initialLayers,
    dataFromLayers,
    layerNamesMapping,
    activeLayerId,
    nonValidLayersIds,
    showLabelsIds,
    visibleLayersIds,
    changedLayersIds,
}: IGetAccordionDataArgs) => {
    const layerId = item.id;
    let showSaveIcon = changedLayersIds.includes(layerId) && !nonValidLayersIds.some((id) => id.includes(layerId));
    let showRevertIcon = changedLayersIds.includes(layerId);
    const title = layerNamesMapping.get(layerId);
    let hasObjects = dataFromLayers[layerId]?.objects?.length === 0 || !dataFromLayers[layerId]?.objects ? false : true;
    const active = layerId === activeLayerId;
    const visible = visibleLayersIds.includes(layerId);
    const showLabels = showLabelsIds.includes(layerId);
    const [initialData] = initialLayers.filter((data) => data.layerType === layerId);

    if (layerId === 'nav_polygons_layer') {
        const objects = dataFromLayers[layerId]?.objects;
        if (objects?.polygons_out.length === 0 && objects?.polygons_in.length === 0) {
            hasObjects = false;
        }
    }

    return { title, active, visible, layerId, showLabels, showSaveIcon, showRevertIcon, hasObjects, initialData };
};
