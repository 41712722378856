import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert, generalReducerValues } from '../../General.reducer';
import { AlertsWrapper, AlertWrapper } from './styles';

/**
 * Компонент Алерт.
 * @param type: string - тип алерта, влияет на цвет фона.
 * @param text: string - текст алерта.
 */
export const Alerts = () => {
    const { alerts } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    // Храним ссылки на таймеры в ref, чтобы не терять их между ререндерингами
    const timersRef = useRef<Record<string, NodeJS.Timeout>>({});

    useEffect(() => {
        alerts.forEach((alert) => {
            // Если алерт показывается, задан lifeTime и таймер ещё не запущен
            if (alert.show && !timersRef.current[alert.id]) {
                // Запускаем таймер
                const timerId = setTimeout(() => {
                    dispatch(toggleAlert({ ...alert, show: false }));
                    // После срабатывания удаляем таймер из ref
                    delete timersRef.current[alert.id];
                }, alert.lifeTime || 20000);

                // Запоминаем идентификатор таймера
                timersRef.current[alert.id] = timerId;
            }
            // Если алерт больше не показывается (show=false) или нет lifeTime,
            // а таймер в ref есть — значит, надо очистить
            if ((!alert.show || !alert.lifeTime) && timersRef.current[alert.id]) {
                clearTimeout(timersRef.current[alert.id]);
                delete timersRef.current[alert.id];
            }
        });

        // Дополнительно можно почистить таймеры для алертов, которые вообще исчезли из массива
        // (если ID пропал). Для этого перебираем ключи таймеров:
        Object.keys(timersRef.current).forEach((alertId) => {
            const stillInAlerts = alerts.some((a) => a.id === alertId);
            if (!stillInAlerts) {
                clearTimeout(timersRef.current[alertId]);
                delete timersRef.current[alertId];
            }
        });
    }, [alerts, dispatch]);

    return alerts.length ? (
        <AlertsWrapper>
            {alerts.map((alert) => {
                return (
                    <AlertWrapper key={alert.id} type={alert.type || 'warning'}>
                        {alert.text}
                    </AlertWrapper>
                );
            })}
        </AlertsWrapper>
    ) : null;
};
