import axios, { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import { toggleAlert, toggleSpinner } from '../General.reducer';
import { IRequestArgs, IToggleSpinnerArgs } from './api.interfaces';
import { getHeaders } from './getHeaders';

/**
 * Get запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const getRequest = async (args: IRequestArgs) => {
    const { url, token, dispatch, allowSpinner = true, alert } = args;
    const toggleSpinner = (show: boolean) => _toggleSpinner(show)({ dispatch, allowSpinner });
    toggleSpinner(allowSpinner);
    const headers = getHeaders(token || '');
    try {
        const response = await axios({
            method: 'get',
            url,
            headers,
            responseType: 'json',
        });
        toggleSpinner(false);
        alert &&
            dispatch &&
            dispatch(
                toggleAlert({
                    id: 'success',
                    show: true,
                    text: alert,
                    type: 'success',
                }),
            );
        return response.data;
    } catch (error) {
        handleError(error as AxiosError, dispatch);
        toggleSpinner(false);
    }
};

/**
 * Post запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const postRequest = async (args: IRequestArgs) => {
    const {
        url,
        token,
        data,
        dispatch,
        additionalParams = null,
        responseType = 'json',
        allowSpinner = true,
        alert,
        throwError,
    } = args;

    if (!data) return;
    const toggleSpinner = (show: boolean) => _toggleSpinner(show)({ dispatch, allowSpinner });
    toggleSpinner(allowSpinner);

    try {
        const headers = getHeaders(token || '');
        const response = await axios({
            method: 'POST',
            url,
            responseType,
            data,
            headers,
            // headers: { ...headers, "Content-Type": "application/x-www-form-urlencoded" },
        });
        toggleSpinner(false);
        alert &&
            dispatch &&
            dispatch(
                toggleAlert({
                    id: 'success',
                    show: true,
                    text: alert,
                    type: 'success',
                }),
            );
        return response.data;
    } catch (error) {
        toggleSpinner(false);
        handleError(error as AxiosError, dispatch);
        if (throwError) throw error;
    }
};

/**
 * PATCH запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const patchRequest = async (args: IRequestArgs) => {
    const { url, token, data, dispatch, additionalParams = null, responseType = 'json', allowSpinner = true } = args;
    const toggleSpinner = (show: boolean) => _toggleSpinner(show)({ dispatch, allowSpinner });
    toggleSpinner(allowSpinner);
    try {
        const headers = getHeaders(token || '');
        const response = await axios({ method: 'PATCH', url, responseType, data, headers });
        toggleSpinner(false);
        return response.data;
    } catch (error) {
        toggleSpinner(false);
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * DELETE запрос.
 * @param args Аргументы для запроса на сервер
 */
export const deleteRequest = async (args: IRequestArgs) => {
    const { url, token, allowSpinner = true, dispatch } = args;
    const toggleSpinner = (show: boolean) => _toggleSpinner(show)({ dispatch, allowSpinner });
    toggleSpinner(allowSpinner);
    try {
        const headers = getHeaders(token || '');
        const response = await axios({ method: 'DELETE', url, headers });
        toggleSpinner(false);
        return response.data;
    } catch (error) {
        toggleSpinner(false);
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * Получение изображения из локального файла.
 *
 * @param url Локальная Blob ссылка на картинку.
 */
export const getLocalBlobImage = async (url: string) => {
    try {
        const response = await axios({
            method: 'get',
            url,
            responseType: 'blob',
            headers: getHeaders(),
        });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError);
    }
};

/**
 * Получение изображения из локального файла.
 *
 * @param url Локальная Blob ссылка на картинку.
 */
export const getLocalBase64Image = async (url: string, type?: string) => {
    try {
        let result;
        await axios({
            method: 'get',
            url,
            responseType: 'blob',
            headers: getHeaders(),
        })
            .then((response) => {
                return new File([response.data], `${response.data.type.replace(`${type || 'image'}/`, '')}`, {
                    type: response.data.type,
                });
            })
            .then(async (file) => {
                result = await convertBase64(file);
            });
        return result;
    } catch (error) {
        handleError(error as AxiosError);
    }
};

/**
 * Конвертация файла в base64.
 *
 * @param file Файл
 */
const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

/**
 * ФВП для спиннера.
 */
const _toggleSpinner =
    (show: boolean) =>
    ({ dispatch, allowSpinner }: IToggleSpinnerArgs) => {
        allowSpinner && dispatch && dispatch(toggleSpinner(show));
    };

/**
 * Обработчик ошибок.
 *
 * @param error Объект описывающий ошибку.
 */
export const handleError = (error: AxiosError, dispatch?: Dispatch): void => {
    if (error.response) {
        // Запрос был сделан и сервер ответил
        dispatch &&
            dispatch(
                toggleAlert({
                    id: 'networkError',
                    show: true,
                    // text: `Network Error! Status: ${error.response.status}`,
                    text: `Network Error! Status: ${error.response.status}, ${JSON.stringify(error.response.data)}`,
                }),
            );
        console.warn(error.response.data);
        console.warn(error.response.status);
        console.warn(error.response.headers);
    } else if (error.request) {
        // Запрос был сделан но ответа не получено
        console.warn(error.request);
    } else {
        // Что-то вызвало ошибку при запросе.

        console.warn('Error', error.message);
    }
};
