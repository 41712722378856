import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectLocation } from '../../../../Locations.interfaces';
import { accountsReducerValues, fetchUsers, storeOnEdit } from '../../Accounts.reducer';
import { dropdownStyles, LocationsCellWrapper, OptionBubble, OptionsWrapper } from './styles';
import { useAppDispatch } from '../../../../../../store';
import { PrimaryButton } from '@fluentui/react';
import { useMutation } from '@tanstack/react-query';
import { generalReducerValues, toggleAlert } from '../../../../../../General.reducer';
import { deleteRequest, postRequest } from '../../../../../../tools/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const LocationsCell: React.FC<{
    locations: IProjectLocation[];
    assignedLocationsIds: number[];
    accountId: number;
    projectId: string;
}> = ({ locations, assignedLocationsIds, accountId, projectId }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const {
        urls: { PROJECT_LOCATIONS_PERMISSIONS_URL },
        token,
    } = useSelector(generalReducerValues);
    const { user2location, accounts, onEdit } = useSelector(accountsReducerValues);
    const [localIds, setLocalIds] = useState<number[]>([]);
    const [disabled, setDisabled] = useState(true);

    const options = locations.map((item) => {
        return { key: item.id, text: item.name };
    });

    useEffect(() => {
        setLocalIds([...assignedLocationsIds]);
    }, [assignedLocationsIds]);

    const mutation = useMutation({
        mutationFn: ({ IdsToDelete, IdsToPost }: { IdsToDelete: number[]; IdsToPost: number[] }) => {
            const url = `${PROJECT_LOCATIONS_PERMISSIONS_URL}`;

            const deleteRequests = IdsToDelete.map((id) => {
                const bindingId = user2location.find(
                    (item) => item.user_id === accountId && item.project_location_id === id,
                )?.id;
                return deleteRequest({ url: `${url}${bindingId}/`, token });
            });

            const postRequests = IdsToPost.map((id) => {
                const data = { project_location_id: id, user_id: accountId };
                return postRequest({ url, token, data });
            });

            return axios.all([...postRequests, ...deleteRequests]).then(() => {});
        },
    });

    const onChange = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        index?: number | undefined,
    ): void => {
        if (option?.key) {
            option.selected && setLocalIds([...localIds, Number(option.key)]);
            !option.selected && setLocalIds(localIds.filter((id) => id !== Number(option.key)));
        }
    };

    const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
        const result = options?.map((option) => {
            return (
                <OptionBubble key={option.key}>
                    <span>{option.text}</span>
                </OptionBubble>
            );
        });
        return <OptionsWrapper>{result}</OptionsWrapper>;
    };

    const onEditLocationsClick = () => {
        const IdsToDelete = assignedLocationsIds?.filter((id) => !localIds.includes(Number(id)));
        const IdsToPost = localIds?.filter((id) => !assignedLocationsIds?.includes(Number(id)));
        mutation.mutate(
            { IdsToDelete, IdsToPost },
            {
                onSuccess: () => {
                    const userName = accounts.find((item) => item.id === accountId)?.username;
                    dispatch(
                        toggleAlert({
                            id: 'Locations changed for user',
                            show: true,
                            type: 'success',
                            text: `${t('Locations changed for user')} ${userName}`,
                            lifeTime: 3000,
                        }),
                    );
                    dispatch(fetchUsers(projectId));
                },
                onError: () => {
                    dispatch(
                        toggleAlert({
                            id: 'Error with saving',
                            show: true,
                            type: 'warning',
                            text: t('Error with saving'),
                            lifeTime: 3000,
                        }),
                    );
                    dispatch(fetchUsers(projectId));
                },
            },
        );
    };

    useEffect(() => {
        if (
            mutation.isPending ||
            (onEdit && onEdit?.type !== 'locations') ||
            (onEdit && onEdit.accountId !== accountId) ||
            (assignedLocationsIds.every((id) => localIds?.includes(id)) &&
                assignedLocationsIds.length === localIds?.length)
        ) {
            setDisabled(() => true);
        } else {
            setDisabled(() => false);
        }
    }, [accountId, assignedLocationsIds, localIds, mutation.isPending, onEdit]);

    useEffect(() => {
        if (!disabled) {
            dispatch(storeOnEdit({ type: 'locations', accountId }));
        } else {
            dispatch(storeOnEdit(null));
        }
    }, [accountId, assignedLocationsIds, disabled, dispatch]);

    const dropdownDisabled =
        (Boolean(onEdit) && onEdit?.type !== 'locations') || (Boolean(onEdit) && onEdit?.accountId !== accountId);

    return (
        <LocationsCellWrapper>
            <Dropdown
                placeholder={t('Select locations')}
                disabled={dropdownDisabled}
                selectedKeys={localIds}
                onChange={onChange}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onRenderTitle={onRenderTitle}
            />
            <PrimaryButton
                text={mutation.isPending ? t('Saving...') : t('Save')}
                disabled={disabled}
                onClick={onEditLocationsClick}
            />
        </LocationsCellWrapper>
    );
};

export default LocationsCell;
