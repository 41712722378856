import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Group, Shape } from 'react-konva';

import {
    navPolygonsValues,
    changeCoords,
    storeNewNavPolygon,
    addAnchor,
    selectNavPolygon,
    clearSelectedObjects,
    toggleContextMenu,
    changeShapeCoords,
    removeLastCoords,
    changeCoordsFullLayer,
} from '../../navPolygons.reducer';
import { drawNewPoint } from './core/drawNewCut';
import { ObjectLabel, Anchors, Helper, DrawNodes } from '../../../../layers';
import { WrapperLayer } from './Draw.styles';

import { formattingCoords } from './core/formattingCoords';
import { useTranslation } from 'react-i18next';

import { getIntersect } from './core/getIntersect';
import { commonTools } from '../../../../layers';
import { IAnchorContextMenuArgs, IExtendedPlan, IPlan } from '../../../../layers.interfaces';
import GeometryBackground from './components/GeometryBackground/GeometryBackground';
import NewShape from './components/NewShape/NewShape';
import { cloneDeep } from 'lodash';
import items from '../../../TracksTraking/components/Controls/components/ObjectOptions/data/data';
import { IObjects, INavPolygon } from '../../navPolygons.interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        showLabels,
        generalSettings: { snapToGrid },
        navPolygonFrontId,
        currentPlanData,
        selectedObjects,
        typeSelect,
        selectedObjectId,
    } = useSelector(navPolygonsValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [coordsEnd, setCoordsEnd] = useState<number[]>([]);

    const [statusOverShape, setStatusOverShape] = useState<boolean>(false);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [isMouseDownMove, setIsMouseDownMove] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (activeToolId === 'draw') {
            const container = props.stage.current.container();
            if (container) {
                container.style.cursor = 'crosshair';
            }
        }
    }, [activeToolId]);

    useEffect(() => {
        const container = props.stage.current.container();
        setCoords([]);
        setCoordsEnd([]);
        setIsDrawNow(false);
        if (activeToolId === 'draw') {
            container.style.cursor = 'crosshair';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'anchorsEdit' && statusOverShape) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId, statusOverShape]);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCoords = (coordsS: number[]) => {
        let tempCoords = coords;

        tempCoords[tempCoords.length - 1] = coordsS;

        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const updateCoordsAnchors = (coordsS: number[][] | undefined, front_id: string) => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(changeCoords({ coordsS, front_id }));
        }
    };

    const onMouseDown = (id: string) => (e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);
        if (activeToolId === 'move') {
            dispatch(selectNavPolygon({ groupFrontId: 'object', itemFrontId: id }));
        } else if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true);
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());

            // setIdSelectedShape('')

            let _objects: INavPolygon[] = [];
            let selected_Objects: number[][] = [];
            objects[typeSelect].forEach((item, i) => {
                if (item.front_id === navPolygonFrontId) {
                    selected_Objects = item.coords.coordinates;
                } else {
                    _objects.push(item);
                }
            });

            if (commonTools.getIntersect(formattingCoords(_objects, selected_Objects, props.scale.stageScale))) {
                dispatch(removeLastCoords());
            } else {
                dispatch(changeShapeCoords());
            }
        } else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false);
        }
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (navPolygonFrontId !== '') {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects[typeSelect].find((item) => item.front_id === navPolygonFrontId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.coords.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: navPolygonFrontId! }));

                setCoordsOldShape(coords);
            }
        } else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: INavPolygon[] = [];
                objects[typeSelect].forEach((item, i) => {
                    _objects.push({
                        ...item,
                        coords: {
                            ...item.coords,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.coords.coordinates,
                                coords,
                                coordsOldShape,
                            ),
                        },
                    });
                });

                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }
        }
    };

    const addNewPoint = (coordsS: number[]) => {
        let tempCoords = coords;
        if (tempCoords.length === 0) {
            tempCoords.push(coordsS);
        }

        tempCoords.push(coordsS);

        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const onHelperClick = (objectId: string) => (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (objectId !== '') {
            if (activeToolId === 'anchorsEdit') {
                if (selectedObjects && selectedObjects.front_id === objectId) {
                    let coords = commonTools.getPointerCoords({ e, snapToGrid: false });
                    e.evt.preventDefault();
                    const coordsTemp = [coords![0], coords![1]];
                    dispatch(addAnchor({ anchorCoords: coordsTemp, objectId }));
                } else {
                    dispatch(selectNavPolygon({ itemFrontId: objectId }));
                }
            }
        } else {
            if (activeToolId === 'draw' && currentPlanData) {
                drawNewPoint({ currentPlanData, snapToGrid, e, updateIsDrawNow, addNewPoint });
            } else {
                e.evt.preventDefault();
                dispatch(clearSelectedObjects());
            }
        }
    };

    const onClickCircleAnd = () => {
        setIsDrawNow(false);
        let tempCoords = coords;
        tempCoords.splice(coords.length - 1, 1);
        dispatch(storeNewNavPolygon(tempCoords));
        setCoords([]);
        setCoordsEnd([]);
    };

    const onShapeMove = (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (newPoint) {
            updateCoords(newPoint);
        }
    };

    const onAnchorContextMenu = (args: IAnchorContextMenuArgs) => {
        const { anchorIndex, pointsNum, cursor } = args;
        dispatch(toggleContextMenu({ show: true, cursor, anchorIndex, pointsNum }));
    };

    const onShapeMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(true);
    };
    const onShapeMouseOut = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(false);
    };

    const labelCoords = (coordsList: number[][]) => {
        return commonTools.findPolygonCenter(coordsList);
    };

    const geometry = useMemo(() => {
        if (!objects[typeSelect] || objects[typeSelect].length === 0) return null;

        const preparedObjects = objects[typeSelect].map((item, i) => ({ ...item, index: i }));
        const [selectedObject] = preparedObjects.filter((item) => item.front_id === selectedObjects?.front_id);

        let colorStroke = typeSelect === 'polygons_in' ? 'rgb(51,143,51)' : 'rgb(255,0,0)';

        const componentNode = preparedObjects
            .sort((a, _) => {
                if (a.front_id === selectedObject?.front_id) return 1;
                return -1;
            })
            .map((item, index) => {
                const selected = item.front_id === selectedObjects?.front_id;
                let coordsList = item.coords.coordinates;

                return (
                    <Group key={index + '+++!!'}>
                        <Shape
                            onClick={onHelperClick(item.front_id)}
                            onMouseOver={onShapeMouseOver}
                            onMouseOut={onShapeMouseOut}
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(coordsList[0][0], coordsList[0][1]);
                                coordsList.forEach((coord, i) => {
                                    if (i > 0) {
                                        context.lineTo(coord[0], coord[1]);
                                    }
                                });
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            // fill={color}
                            opacity={1}
                            fill={navPolygonFrontId === item.front_id ? 'rgba(255,0,0,.3)' : 'rgb(241,238,53,.5)'}
                            stroke={colorStroke}
                            onMouseDown={onMouseDown(item.front_id)}
                            strokeWidth={
                                navPolygonFrontId === item.front_id
                                    ? 4 / props.scale.stageScale
                                    : 2 / props.scale.stageScale
                            }
                        />
                        {navPolygonFrontId === item.front_id ? (
                            <Anchors
                                activeToolId={activeToolId}
                                coords={coordsList}
                                updateCoords={updateCoords}
                                updateCoordsAnchors={updateCoordsAnchors}
                                scale={props.scale}
                                front_id={item.front_id}
                                currentPlanData={currentPlanData!}
                                snapToGrid={snapToGrid}
                                onAnchorContextMenu={onAnchorContextMenu}
                            />
                        ) : null}
                        {labelCoords(coordsList) && !isDrawNow && showLabels && !selected && (
                            <ObjectLabel
                                stageScale={props.scale.stageScale}
                                x={labelCoords(coordsList)?.x}
                                y={labelCoords(coordsList)?.y}
                                marker={String(item.index)}
                                selected={false}
                            />
                        )}
                    </Group>
                );
            });

        return (
            <>
                <Group>{componentNode}</Group>
                {selectedObject && !isDrawNow && showLabels && (
                    <ObjectLabel
                        stageScale={props.scale.stageScale}
                        x={labelCoords(selectedObject.coords.coordinates)?.x}
                        y={labelCoords(selectedObject.coords.coordinates)?.y}
                        marker={String(selectedObject.index)}
                        selected={true}
                    />
                )}
            </>
        );
    }, [
        objects,
        activeToolId,
        props.scale,
        typeSelect,
        isDrawNow,
        showLabels,
        navPolygonFrontId,
        selectedObjects?.front_id,
    ]);

    return (
        currentPlanData && (
            <>
                <GeometryBackground
                    typeSelect={typeSelect}
                    objects={objects}
                    activeToolId={activeToolId}
                    navPolygonFrontId={navPolygonFrontId}
                    scale={props.scale}
                />
                <WrapperLayer key={props.key} onMouseMove={moveShape} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}>
                    {activeToolId === 'draw' && <DrawNodes stageScale={props.scale.stageScale} coordinates={coords} />}
                    <Helper
                        updateCoords={updateCoords}
                        isDrawNow={isDrawNow}
                        currentPlanData={currentPlanData}
                        snapToGrid={snapToGrid}
                        onHelperClick={onHelperClick('')}
                    />
                    {geometry}
                    {activeToolId === 'draw' && (
                        <Helper
                            updateCoords={updateCoords}
                            isDrawNow={isDrawNow}
                            currentPlanData={currentPlanData}
                            snapToGrid={snapToGrid}
                            onHelperClick={onHelperClick('')}
                        />
                    )}
                </WrapperLayer>
                <NewShape
                    scale={props.scale}
                    key={props.key}
                    onShapeMove={onShapeMove}
                    onHelperClick={onHelperClick}
                    coords={coords}
                    isLayerActive={props.isLayerActive}
                    coordsEnd={coordsEnd}
                    onClickCircleAnd={onClickCircleAnd}
                />
            </>
        )
    );
};

export default Draw;
