import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { generalReducerValues, toggleAlert } from '../../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IProject2Product } from '../../../../../Projects/interfaces';
import { IProduct } from '../../../Accounts/Accounts.interfaces';
import { Dropdown, IDropdownOption, PrimaryButton } from '@fluentui/react';
import { Controls, OptionBubble, OptionsWrapper, ProductWrapper, dropdownStyles } from './styles';
import { Title } from '../../styles';
import { useTranslation } from 'react-i18next';
import SpinnerComponent from '../../../../../Spinner/Spinner';
import { getRequest, postRequest, deleteRequest } from '../../../../../../tools/api';
import axios from 'axios';
import { useAppDispatch } from '../../../../../../store';

export const Products: React.FC = () => {
    const {
        urls: { PROJECT_2_PRODUCT_URL, PRODUCTS_URL },
        token,
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();

    const params = useParams();
    const { t } = useTranslation();
    const [assignedProductIds, setAssignedProductIds] = useState<number[]>([]);
    const projectId = params.projectId;

    const project2Product = useQuery({
        queryKey: ['project-2-product', projectId],
        queryFn: () => {
            const url = `${PROJECT_2_PRODUCT_URL}?project_id=${projectId}`;
            return getRequest({ url, token }).then((data) => data as IProject2Product[]);
        },
        staleTime: 15 * 60 * 1000,
    });

    const mutation = useMutation({
        mutationFn: ({ IdsToDelete, IdsToPost }: { IdsToDelete: number[]; IdsToPost: number[] }) => {
            const url = `${PROJECT_2_PRODUCT_URL}`;

            const deleteRequests = IdsToDelete.map((id) => {
                const bindingId = project2Product?.data?.find((item) => item.product_id === id)?.id;
                return deleteRequest({ url: `${url}${bindingId}/`, token });
            });
            const postRequests = IdsToPost.map((id) => {
                const product_name = availableProducts.data?.find((item) => item.id === id)?.name;
                const data = { product_id: id, project_id: Number(projectId) };
                return postRequest({ url, token, data });
            });

            return axios.all([...postRequests, ...deleteRequests]).then(() => {});
        },
    });

    const availableProducts = useQuery({
        queryKey: ['products'],
        queryFn: () => {
            const url = `${PRODUCTS_URL}`;
            return getRequest({ url, token }).then((data) => data as IProduct[]);
        },
        staleTime: 15 * 60 * 1000,
    });

    const initialIds = project2Product.data?.map((item) => item.product_id);

    useEffect(() => {
        initialIds && setAssignedProductIds(initialIds);
    }, [project2Product.data]);

    const onChange = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        index?: number | undefined,
    ): void => {
        if (option) {
            option.key && option.selected && setAssignedProductIds([...assignedProductIds, Number(option.key)]);
            option.key &&
                !option.selected &&
                setAssignedProductIds(assignedProductIds.filter((id) => id !== Number(option.key)));
        }
    };

    const options: IDropdownOption[] = useMemo(() => {
        const availableIds = availableProducts?.data ? availableProducts?.data.map((item) => item.id) : [];
        const assignedUnavailableOptions: IDropdownOption[] =
            project2Product.data
                ?.filter((item) => !availableIds.includes(item.product_id))
                .map((item) => ({
                    key: item.product_id,
                    disabled: true,
                    selected: true,
                    text: item.product_name,
                })) ?? [];
        const options = availableProducts?.data
            ? availableProducts?.data.map((item: IProduct) => {
                  return { key: item.id, text: item.name };
              })
            : [];

        return [...options, ...assignedUnavailableOptions];
    }, [availableProducts?.data, project2Product.data]);

    const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
        const result = options?.map((option) => {
            return <OptionBubble key={option.key}>{option.text}</OptionBubble>;
        });
        return <OptionsWrapper>{result}</OptionsWrapper>;
    };

    const isLoading = availableProducts.isFetching || project2Product.isFetching;

    const disabled = useMemo(() => {
        return (
            assignedProductIds.every((id) => initialIds?.includes(id)) &&
            assignedProductIds.length === initialIds?.length
        );
    }, [assignedProductIds, initialIds]);

    const onEditProductsClick = () => {
        const IdsToDelete = initialIds?.filter((id) => !assignedProductIds.includes(Number(id))) ?? [];
        const IdsToPost = assignedProductIds?.filter((id) => !initialIds?.includes(Number(id)));
        mutation.mutate(
            { IdsToDelete, IdsToPost },
            {
                onSuccess: () => {
                    // const userName = accounts.find((item) => item.id === accountId)?.username;
                    dispatch(
                        toggleAlert({
                            id: 'Products changed',
                            show: true,
                            type: 'success',
                            text: `${t('Products changed')}`,
                            lifeTime: 3000,
                        }),
                    );
                    project2Product.refetch();
                },
                onError: () => {
                    dispatch(
                        toggleAlert({
                            id: 'Error with saving',
                            show: true,
                            type: 'warning',
                            text: t('Error with saving'),
                            lifeTime: 3000,
                        }),
                    );
                    project2Product.refetch();
                },
            },
        );
    };

    return (
        <ProductWrapper>
            <Title>{`${t('Edit products')}`}</Title>
            <Controls>
                {isLoading ? (
                    <SpinnerComponent />
                ) : (
                    <Dropdown
                        placeholder="Select options"
                        selectedKeys={assignedProductIds}
                        onChange={onChange}
                        multiSelect
                        options={options}
                        styles={dropdownStyles}
                        onRenderTitle={onRenderTitle}
                    />
                )}
                <PrimaryButton text={t('Save')} disabled={disabled} onClick={onEditProductsClick} />
            </Controls>
        </ProductWrapper>
    );
};
