import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { IPassWay } from '../../../../passWays.interfaces';
import {
    passWaysReducerValues,
    selectObject,
    deleteObject,
    storeInitialObjects,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeVersionsData,
    storeGeneralSettings,
    toggleShowLabels,
    storeAnotherLocationNames,
    storeHotAddObject,
    pasteObjectFromClipboard,
} from '../../../../passWays.reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames } from './ObjectsList.styles';
import { commonTools } from '../../../../../../layers';

/**
 * Компонент списка объектов слоя
 */
const ObjectsList = ({ ...props }) => {
    const scollToRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { objects, selectedObjectId, createdAt, layerAlias, generalSettings, selectedObjectMarker } =
        useSelector(passWaysReducerValues);

    useEffect(() => {
        if (props.clipboardData) {
            if ((props.clipboardData.obj as IPassWay).marker !== selectedObjectMarker) {
                props.toggleAlertTrans &&
                    props.toggleAlertTrans({
                        text: t('Wrong passway marker selected'),
                        show: true,
                    });
            } else {
                dispatch(pasteObjectFromClipboard(props.clipboardData.obj));
            }
        }
    }, [props.clipboardData]);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.revertLayer && dispatch(storeInitialObjects(props.initialData.data));
    }, [props.revertLayer]);

    // useEffect(() => {
    //     props.toggleLayerContextMenu({ layerAlias, contextMenu });
    // }, [contextMenu.show]);

    useEffect(() => {
        props.layerChange({ id: layerAlias, objects, lastCreatedAt: createdAt, selectedObjectId });
    }, [objects, selectedObjectId]);

    useEffect(() => {
        dispatch(storeAnotherLocationNames(props.anotherLocationLayers));
    }, [props.anotherLocationLayers]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(selectObject());
    }, [props.active]);

    useEffect(() => {
        if (!props.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    const { t } = useTranslation();

    useEffect(() => {
        scollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, [scollToRef, selectedObjectId]);

    const onObjectClick = (id: string) => () => {
        dispatch(selectObject(id));
    };

    const onDeleteIconClick = (id: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(deleteObject(id));
    };

    const objectsList = objects.map((object: IPassWay) => {
        const selected = object.id === selectedObjectId;
        return selected ? (
            <div key={object.id} ref={scollToRef}>
                <ObjectWrapper key={object.id} selected={selected} onClick={onObjectClick(object.id)}>
                    {commonTools.getObjectLabelByMode({ objNamingMode: props?.generalSettings?.objNamingMode, object })}
                    {!generalSettings.readOnly && (
                        <FontIcon
                            iconName="Cancel"
                            className={classNames.salmon}
                            onClick={onDeleteIconClick(object.id)}
                        />
                    )}
                </ObjectWrapper>
            </div>
        ) : (
            <ObjectWrapper key={object.id} selected={selected} onClick={onObjectClick(object.id)}>
                {commonTools.getObjectLabelByMode({ objNamingMode: props?.generalSettings?.objNamingMode, object })}
                {!generalSettings.readOnly && (
                    <FontIcon iconName="Cancel" className={classNames.salmon} onClick={onDeleteIconClick(object.id)} />
                )}
            </ObjectWrapper>
        );
    });

    return <ObjectsListWrapper>{objectsList}</ObjectsListWrapper>;
};

export default ObjectsList;
