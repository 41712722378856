import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './perimeter.store';

import { IPerimeterReducer, IPerimeter, IObjects } from './perimeter.interfaces';
import { IPlan } from '../../../Plans/Plans.interfaces';
import { commonTools } from '../../../../tools/commonTools';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { IGeneralSettings, INodeContextMenu, IHotAddObject, IVersionsData } from '../../layers.interfaces';
import { sizes } from '../../../../constants/sizes';

const initialState: IPerimeterReducer = {
    layerAlias: 'perimeter_layer',
    selectedObjectId: null,
    activeToolId: null,
    objects: [],
    createdAt: undefined,
    currentPlanData: null,
    showLabels: false,
    generalSettings: {} as IGeneralSettings,
    perimeterFrontId: '',
    selectedObjects: null,
    contextMenu: { show: false },
    versionsData: null,
    hotAddObject: null,
    objectsTemp: [],
};

export const PerimeterReducer = createSlice({
    name: 'PerimeterReducer',
    initialState,
    reducers: {
        /**
         * Вставка периметра из буфера обмена
         */
        pasteObjectFromClipboard: (state, action: PayloadAction<IPerimeter>) => {
            const { objects, versionsData, perimeterFrontId } = cloneDeep(state);

            if (perimeterFrontId) {
                const front_id = `v${String(versionsData?.activeVersionId)}:passway:${commonTools.generateId()}`;
                const newObjects = objects.filter((item) => item.front_id !== perimeterFrontId);
                const newObject = cloneDeep({ ...action.payload, front_id });
                commonTools.shiftCoords(newObject, [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT]);
                newObjects.push(newObject);

                state.objects = newObjects;
                state.perimeterFrontId = front_id;
                state.selectedObjects = newObject;
            } else {
                console.log('error');
            }
        },
        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },
        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Открывает контекстное меню с выбором: удалить узелок (если их больше 3) или удалить весь рисунок
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ perimeterFrontId: string; anchorIndex: number }>) => {
            const { perimeterFrontId, anchorIndex } = action.payload;
            const objects = cloneDeep(state.objects).map((perimeter: IPerimeter) => {
                if (perimeter.front_id === perimeterFrontId) {
                    const coordinates = cloneDeep(perimeter.coords.coordinates);
                    coordinates.splice(anchorIndex, 1);
                    const result = { ...perimeter, coords: { ...perimeter.coords, coordinates } };
                    return result;
                }
                return perimeter;
            });

            state.objects = objects;
            state.objectsTemp = objects;
        },

        /***
         * Созжание нового периметра
         * @param state
         * @param action
         */
        storeNewPerimeter: (state, action: PayloadAction<Array<Array<number>>>) => {
            const objects = cloneDeep(state.objects);
            const objects2 = cloneDeep(state.objects);
            const id = `perimeter:${+DateTime.local()}:${commonTools.generateId()}`;

            objects2.push({
                front_id: id,
                user_area: null,
                coords: {
                    type: 'Polygon',
                    coordinates: action.payload,
                },
            });

            state.objects = objects2;
            state.objectsTemp = objects;
        },
        /***
         * Изменение координат выделеного объекта
         * @param state
         * @param action
         */
        changeCoords: (state, action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string }>) => {
            const { coordsS, front_id } = action.payload;
            const objects = cloneDeep(state.objects);

            const index = objects.findIndex((item) => item.front_id === front_id);
            index !== -1 &&
                (objects[index] = {
                    ...objects[index],
                    coords: {
                        ...objects[index].coords,
                        coordinates: coordsS!,
                    },
                });

            state.objects = objects;
        },
        /***
         * Запись в стор загруженных с сервера объектов
         * @param state
         * @param action
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IPerimeter>>) => {
            const modifyObjects = (arr: IPerimeter[]) => {
                return Array.isArray(arr)
                    ? arr.map((item) => {
                          if (item.user_area === undefined) {
                              return { ...item, user_area: null };
                          } else {
                              return item;
                          }
                      })
                    : [];
            };

            state.objects = modifyObjects(action.payload);
            state.objectsTemp = modifyObjects(action.payload);
        },
        /**
         * Запись в стор поля createdAt
         * @param state
         * @param action
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },
        /**
         * Переключение показа маркеров на объектах
         * @param state
         * @param action
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Добавление новых точек в полигон
         * @param state
         * @param action
         */
        addAnchor: (
            state,
            action: PayloadAction<{ anchorCoords?: number[] | undefined; objectId?: string | undefined }>,
        ) => {
            const { anchorCoords, objectId } = action.payload;
            const objects = cloneDeep(state.objects);
            let objectsTemp = [];

            const places = objects.map((element) => {
                if (element.front_id !== objectId) {
                    return element;
                } else {
                    // console.log('>>>>>>>>>>>.', element.coords.coordinates)
                    const coords = element.coords.coordinates;
                    let index = null;
                    let statusAndAnchors = false;
                    coords.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, anchorCoords!)) {
                                index = i;
                                statusAndAnchors = false;
                            }
                        } else if (i === 0) {
                            if (commonTools.between(arr[arr.length - 1], point, anchorCoords!)) {
                                statusAndAnchors = true;
                                index = null;
                            }
                        }
                    });

                    if (index !== null) {
                        coords.splice(index, 0, anchorCoords!);
                    } else if (statusAndAnchors) {
                        coords.push(anchorCoords!);
                    }
                    return {
                        ...element,
                        coords: {
                            ...element.coords,
                            coordinates: coords,
                        },
                    };
                }
            });

            objectsTemp = cloneDeep(objects);
            objectsTemp = places;
            //
            state.objects = objectsTemp;
            state.objectsTemp = objectsTemp;
        },

        /**
         * Выбор объекта
         * @param state
         * @param action
         */
        selectPerimeter: (state, action: PayloadAction<{ perimeterFrontId: string | undefined }>) => {
            const { perimeterFrontId } = action.payload;
            const objects = cloneDeep(state.objects);
            const selectedObjects = objects.find((item) => item.front_id === perimeterFrontId) || null;

            state.selectedObjects = selectedObjects;
            state.perimeterFrontId = perimeterFrontId!;
        },

        /**
         * Уделение объекта
         * @param state
         * @param action
         */
        deletePerimeter: (state, action: PayloadAction<{ perimeterFrontId: string }>) => {
            const { perimeterFrontId } = action.payload;
            let objects = cloneDeep(state.objects);
            let indexTemp = objects.findIndex((item) => item.front_id === perimeterFrontId);

            objects.splice(indexTemp!, 1);

            state.selectedObjects = null;
            state.objects = objects;
            state.objectsTemp = objects;
        },

        /**
         * Очистка выделеного объекта
         * @param state
         * @param action
         */
        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjects = null;
            state.perimeterFrontId = '';
        },
        changeShapeCoords: (state, action: PayloadAction) => {
            state.objectsTemp = state.objects;
        },

        removeLastCoords: (state, action: PayloadAction) => {
            state.objects = state.objectsTemp;
        },

        changeCoordsFullLayer: (state, action: PayloadAction<IObjects>) => {
            state.objects = action.payload;
        },

        changeObjectOptions: (state, action: PayloadAction<{ userArea: string }>) => {
            const { userArea } = action.payload;
            let objects = cloneDeep(state.objects);
            let placesFrontId = cloneDeep(state.perimeterFrontId);

            const modifiedObjects = objects.map((item) => {
                if (item.front_id !== placesFrontId) {
                    return item;
                } else {
                    return { ...item, user_area: Number(userArea) > 0 ? Number(userArea) : null };
                }
            });

            state.objects = modifiedObjects;
        },
    },
});

export const {
    toggleTool,
    storeGeneralSettings,
    changeCoords,
    changeObjectOptions,
    storeNewPerimeter,
    storeInitialCreatedAt,
    storeCurrentPlanData,
    storeInitialObjects,
    toggleShowLabels,
    pasteObjectFromClipboard,
    addAnchor,
    selectPerimeter,
    deletePerimeter,
    storeVersionsData,
    clearSelectedObjects,
    toggleContextMenu,
    deleteAnchor,
    storeHotAddObject,
    changeShapeCoords,
    removeLastCoords,
    changeCoordsFullLayer,
} = PerimeterReducer.actions;

export const perimeterValues = (state: RootState) => state.PerimeterReducer;

export default PerimeterReducer.reducer;
