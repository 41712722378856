import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from './store';
import { commonTools } from '../../layers';
import { IGeneralSettings, IPlan, IPoint, IHotAddObject, IVersionsData } from '../../layers.interfaces';

import { IObjects, IRadioSensor, IRadioSensorsReducer } from './interfaces';

export const initialState: IRadioSensorsReducer = {
    activeToolId: null,
    selectedObjectId: null,
    generalSettings: {} as IGeneralSettings,
    currentPlanData: null,
    createdAt: undefined,
    objects: [],
    versionsData: null,
    allLocationNames: [],
    showLabels: false,
    sensorTypesDict: [
        'wifi_mac_sensor',
        'wifi_access_point',
        'wifi_controller',
        'bluetooth_mac_sensor',
        'ibeacon_sensor',
        'ibeacon_transmitter',
    ],
    layerAlias: 'radio_sensor_ipoints_layer',
    anotherLocationNames: { markers: [] },
    hotAddObject: null,
};

export const reducer = createSlice({
    name: 'reducer',
    initialState,
    reducers: {
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Сохранение нового объекта в сторе
         */
        storeNewObject: (state, action: PayloadAction<{ point: Array<number> }>) => {
            const { objects, anotherLocationNames } = cloneDeep(state);

            const currentMarkers = objects.map((item) => item.marker);

            const nextNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers, ...currentMarkers],
                'radio_ipoint',
            );

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:radio_ipoint${nextNumber}`;
            const front_id = `v${String(activeVersionId)}:radio_ipoint:${commonTools.generateId()}`;

            const point: IPoint = { type: 'Point', coordinates: action.payload.point };

            const newObject: IRadioSensor = {
                point,
                marker,
                front_id,
                sensor_types: [initialState.sensorTypesDict[0]],
                wifi_scanner_radius: 8,
                is_active: true,
            };

            objects.push(newObject);
            state.objects = objects;
            state.selectedObjectId = front_id;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Выбор объекта
         */
        selectObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload === undefined) {
                state.selectedObjectId = null;
            } else {
                state.selectedObjectId = action.payload;
            }
        },

        /**
         * Удаление объекта
         */
        deleteObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload !== undefined) {
                const { objects, selectedObjectId } = cloneDeep(state);
                state.objects = objects.filter((item) => item.front_id !== action.payload);
                if (selectedObjectId === action.payload) {
                    state.selectedObjectId = null;
                }
            }
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof IRadioSensor; newValue?: string; front_id: string }>,
        ) => {
            const { front_id, key, newValue } = action.payload;
            if (!newValue) return;
            const objects = cloneDeep(state.objects);
            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else if (key === 'sensor_types') {
                    const { sensor_types } = item;
                    if (sensor_types.includes(newValue)) {
                        if (sensor_types.length === 1) return item;
                        const index = sensor_types.indexOf(newValue);
                        sensor_types.splice(index, 1);
                    } else {
                        sensor_types.push(newValue);
                    }
                    return { ...item, sensor_types };
                } else if (key === 'wifi_scanner_radius') {
                    return { ...item, [key]: Number(newValue) };
                } else {
                    return { ...item, [key]: newValue };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Изменение параметров объекта
         */
        changeIsActive: (state, action: PayloadAction<{ isChecked?: boolean; front_id: string }>) => {
            const { front_id, isChecked } = action.payload;
            if (isChecked === undefined) return;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    return { ...item, is_active: isChecked };
                }
            });
            state.objects = objects;
        },

        /**
         * Изменение положения сенсора
         */
        moveObject: (state, action: PayloadAction<{ front_id: string; newCoords: Array<number> }>) => {
            const { front_id, newCoords } = action.payload;
            const temp = cloneDeep(state.objects) || [];
            const objects = temp.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const { point } = item;
                    point.coordinates = newCoords;
                    return { ...item, point };
                }
            });
            state.objects = objects;
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IRadioSensor>>) => {
            state.objects = action.payload || [];
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор всех имен слоя в этой локации
         */
        storeAllLocationNames: (state, action: PayloadAction<Array<string>>) => {
            state.allLocationNames = action.payload;
        },

        /**
         * Сохранение в стор всех имен из других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];

            action?.payload.forEach((item: { data?: any }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string }) => {
                    markers.push(obj.marker);
                });
            });
            const anotherLocationNames = { markers };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },
        changeCoordsFullLayer: (state, action: PayloadAction<IObjects>) => {
            state.objects = action.payload;
        },
        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjectId = null;
        },

        changeCoords: (state, action: PayloadAction<{ coordsS: number[] | undefined; front_id: string }>) => {
            const { coordsS, front_id } = action.payload;
            const objects = cloneDeep(state.objects);

            const index = objects.findIndex((item) => item.front_id === front_id);
            index !== -1 &&
                (objects[index] = {
                    ...objects[index],
                    point: {
                        ...objects[index].point,
                        coordinates: coordsS!,
                    },
                });

            state.objects = objects;
        },
    },
});

export const {
    toggleTool,
    selectObject,
    storeCurrentPlanData,
    storeNewObject,
    moveObject,
    storeInitialObjects,
    storeInitialCreatedAt,
    deleteObject,
    storeGeneralSettings,
    storeVersionsData,
    changeObjectParams,
    toggleShowLabels,
    changeIsActive,
    storeAllLocationNames,
    storeAnotherLocationNames,
    storeHotAddObject,
    changeCoordsFullLayer,
    clearSelectedObjects,
    changeCoords,
} = reducer.actions;

export const reducerValues = (state: RootState) => state.reducer;

export default reducer.reducer;
