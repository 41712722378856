import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { editorReducerValues, changeNonValidLayersIds } from '../../../Editor.reducer';
import { toggleAlert } from '../../../../../General.reducer';
import { EditorContext } from '../../../Editor.context';

import { IExtendedPlan } from '../../../../Layers/layers.interfaces';
import { useAppDispatch } from '../../../../../tools/useSettings';
import { validateGeometry } from '../../../tools/validateGeometry';
import { commonTools } from '../../../../../tools/commonTools';
import { compareGroupsNames } from '../../../../Layers/components/Zones/tools/compareGroupsNames';
import { IGroupZones } from '../../../../Layers/components/Zones/zones.interfaces';
import { compareZonesNames } from '../../../../Layers/components/Zones/tools/compareZonesNames';

export const useValidateLayers = ({ mainPlan }: { mainPlan: IExtendedPlan }) => {
    const { dataFromLayers, activeLayerId, allInitialLayers, changedLayersIds } = useSelector(editorReducerValues);
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const { currentPlanData } = useContext(EditorContext);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const validateFields = ({
        field,
        layerData,
        layerId,
    }: {
        field: string;
        layerData: { objects: Record<string, string>[] };
        layerId: string;
    }) => {
        const currentFields = layerData.objects
            ?.filter((item: Record<string, string>) => item?.[field])
            ?.map((item: Record<string, string>) => item?.[field]);

        const fieldsOfTheSameLayerType = allInitialLayers
            ?.filter((lay) => lay.layerType === layerId)
            ?.filter((item) => item.mainPlanId !== mainPlan.planId)
            ?.filter((item) => item?.data)
            ?.reduce<string[]>((acc, item) => {
                const markers = item.data
                    ?.filter((item: Record<string, string>) => item?.[field])
                    ?.map((item: Record<string, string>) => item?.[field]);
                return [...acc, ...markers];
            }, []);

        const isFieldsValid = commonTools.isNamesValid([...currentFields, ...fieldsOfTheSameLayerType]);

        return isFieldsValid;
    };

    const getAnotherLocationMarkersNames = (args: Array<object>) => {
        const markers: string[] = [];
        const names: string[] = [];

        args.forEach((item: { data?: Array<{ marker: string; name: string; front_id: string }> }) => {
            if (!item.data) return;
            item?.data.forEach((obj: { marker: string; name: string; front_id: string }) => {
                markers.push(`${obj.marker}✚${obj.front_id}`);
                names.push(`${obj.name}✚${obj.front_id}`);
            });
        });
        return { markers, names };
    };

    const validateCrossFloor = ({
        layerData,
        layerId,
    }: {
        layerData: { objects: { marker: string; name: string; front_id: string }[] };
        layerId: string;
    }): {
        valid: boolean;
        names: string[];
    } => {
        const filteredOtherLayers = allInitialLayers
            .filter((lay) => lay.layerType === layerId)
            .filter((item) => item.mainPlanId !== mainPlan.planId)
            .filter((item) => item?.data);

        const anotherLocationMarkersNames = getAnotherLocationMarkersNames(filteredOtherLayers);

        return commonTools.validateCrossFloorMarkersAndNames(layerData.objects, anotherLocationMarkersNames);
    };

    const validateMap: Record<
        string,
        (args: { layerData: { objects: any[] }; layerId: string }) => {
            valid: boolean;
            names: string[];
        }
    > = {
        pass_ways_layer: ({
            layerData,
            layerId,
        }: {
            layerData: { objects: Record<string, string>[] };
            layerId: string;
        }): {
            valid: boolean;
            names: string[];
        } => {
            return ['marker', 'name'].reduce<{ valid: boolean; names: string[] }>(
                (acc, field) => {
                    const { valid, names } = validateFields({ field, layerData, layerId });
                    return { valid: acc.valid && valid, names: [...acc.names, ...names] };
                },
                { valid: true, names: [] },
            );
        },
        nav_polygons_layer: () => ({ valid: true, names: [] }),

        lifts_layer: validateCrossFloor,
        escalators_layer: validateCrossFloor,
        stairs_layer: validateCrossFloor,

        zones_layer: ({
            layerData,
            layerId,
        }: {
            layerData: { objects: IGroupZones[] };
            layerId: string;
        }): {
            valid: boolean;
            names: string[];
        } => {
            const filteredOtherLayers = allInitialLayers
                .filter((lay) => lay.layerType === layerId)
                .filter((item) => item.mainPlanId !== mainPlan.planId)
                .filter((item) => item?.data);

            const hasRepeats = layerData.objects.reduce<boolean>((acc, group) => {
                const { front_id, zones } = group;

                const isGroupsValid = compareGroupsNames(
                    layerData.objects as unknown as IGroupZones[],
                    filteredOtherLayers,
                    front_id ?? '',
                );

                const isZonesValid = zones.reduce<boolean>((acc, zone) => {
                    const isZoneValid = compareZonesNames(
                        layerData.objects as unknown as IGroupZones[],
                        group as unknown as IGroupZones,
                        filteredOtherLayers,
                        zone.front_id,
                        zone.zone_name,
                        zone.zone_marker,
                    );
                    return acc && isZoneValid;
                }, true);

                return acc && isGroupsValid && isZonesValid;
            }, true);

            return { valid: hasRepeats, names: [] };
        },
    };

    useEffect(() => {
        Object.entries(dataFromLayers)
            ?.filter(([id]) => id === activeLayerId)
            ?.forEach(([layerId, layerData]) => {
                const alertId = `${currentPlanData?.floor}_${layerId}_names_validation`;

                const isMarkersValid = validateMap?.[layerId]
                    ? validateMap[layerId]({ layerData, layerId })
                    : validateFields({ field: 'marker', layerData, layerId });

                if (!isMarkersValid.valid) {
                    dispatch(changeNonValidLayersIds({ id: `names_${layerId}`, show: true }));

                    const text = `${t('There are the same markers or names >> ')}
                        floor: ${currentPlanData?.floor}, layer: ${layerId}
                        names/markers: ${isMarkersValid?.names?.join(', ')}`;
                    console.warn(text);

                    dispatch(toggleAlert({ id: alertId, show: true, text, lifeTime: 1 * 60 * 60 * 1000 }));
                } else {
                    dispatch(changeNonValidLayersIds({ id: `names_${layerId}`, show: false }));
                    dispatch(toggleAlert({ id: alertId, show: false }));
                }
            });
    }, [activeLayerId, allInitialLayers, currentPlanData?.floor, dataFromLayers, dispatch, mainPlan.planId, t]);

    useEffect(() => {
        Object.entries(dataFromLayers)
            .filter(([id]) => id === activeLayerId)
            .forEach(([layerId, layerData]) => {
                const geometryValidation = validateGeometry(layerData);
                const alertId = `${currentPlanData?.floor}_${layerId}_geometry_validation`;
                if (geometryValidation.errors.length) {
                    dispatch(changeNonValidLayersIds({ id: `geometry_${layerId}`, show: true }));

                    const text = `${t('There are some geometry intersections >> ')}
                        floor: ${currentPlanData?.floor}, layer: ${layerId}`;

                    dispatch(toggleAlert({ id: alertId, show: true, text, lifeTime: 1 * 60 * 60 * 1000 }));
                } else {
                    dispatch(changeNonValidLayersIds({ id: `geometry_${layerId}`, show: false }));
                    dispatch(toggleAlert({ id: alertId, show: false }));
                }
            });
    }, [activeLayerId, dataFromLayers, currentPlanData?.floor, t]);

    // Проверка на изменение массива changedLayersIds
    useEffect(() => {
        // Если массив непустой:
        if (changedLayersIds.length > 0) {
            // Запускаем интервал, только если его ещё нет
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    dispatch(
                        toggleAlert({
                            id: 'saveLayersAlert',
                            show: true,
                            type: 'warning',
                            text: t('You need to save changed layers: ') + changedLayersIds.join(', '),
                        }),
                    );
                }, 60 * 1000 * 5);
            }
        } else {
            // Если массив пустой, останавливаем интервал (если был)
            if (intervalRef.current) {
                clearInterval(intervalRef.current as NodeJS.Timeout);
                intervalRef.current = null;
            }
        }

        // Чистим интервал при размонтировании или при обновлении эффекта:
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current as NodeJS.Timeout);
                intervalRef.current = null;
            }
        };
    }, [changedLayersIds]);
};
