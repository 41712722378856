import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontIcon, PivotItem, TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import {
    storePlanToAddMeasureCut,
    storePlanToEdit,
    plansReducerValues,
    fetchPlans,
    storePdfModalStatus,
    fetchPlData,
    uploadPdfToBack,
} from './Plans.reducer';
import { locationsReducerValues } from '../Locations/Locations.reducer';
import { versionsReducerValues, fetchVersions } from '../Versions/Versions.reducer';
import { resetEditor } from '../Editor/Editor.reducer';
import { generalReducerValues, toggleAlert } from '../../General.reducer';
import { settingsReducerValues } from '../Settings/Settings.reducer';
import { PlansWrapper, StyledPivot } from './Plans.styles';
import { IPlan, IUploadPDFArgs } from './Plans.interfaces';
import { GeneralContext } from '../../General.context';
import { PlansContext } from './Plans.context';
import List from './components/List/List';
import Modal from '../Modals/Modals';
import Editor from '../Editor/Editor';
import { useParams } from 'react-router-dom';
import AddNewPlan from './components/AddNewPlan/AddNewPlan';
import StackHorizontal from '../StackHorizontal/StackHorizontal';

import FilterInput from '../FilterInput/FilterInput';

import layers from '../Layers/layers';
import { ILocation } from '../Locations/Locations.interfaces';
import axios from 'axios';
import UploadPdf from './components/UploadPdf/UploadPdf';
import { Checkbox, IStackTokens, Stack } from '@fluentui/react';
import ObjectManagement from './components/ObjectsManagement/ObjectsManagement';
import { useAppDispatch } from '../../store';
import useRequestStructures from '../../tools/useRequestStructures/useRequestStructures';
import useRequestRawRelations from '../../tools/useRequestRawRelations/useRequestRawRelations';
import { getHeaders } from '../../tools/getHeaders';
import { LocationManagement } from './components/LocationManagement/LocationManagement';

/**
 * Отображает список планов (чертежей) для конкретной локации.
 */
const Plans = React.memo(() => {
    const params = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedTab, setSelectedTab] = useState('plansList');
    const [modalStatus, setModalStatus] = useState({ show: false });
    const [showLeasingPlans, setShowLeasingPlans] = useState(false);
    const { activeLocation, activeProjectLocation } = useSelector(locationsReducerValues);
    const {
        urls: { PLANS_URL, LOCATIONS_URL, GEO_URL, VERSIONS_URL, PROJECT_LOCATIONS_URL },
    } = useSelector(generalReducerValues);
    const { planToAddMeasureCut, plansList, pdfModalStatus } = useSelector(plansReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const { versions } = useSelector(versionsReducerValues);
    const { token, theme } = useContext(GeneralContext);
    const { neutralLighterAlt, neutralDark } = theme.palette;

    useRequestStructures({ plId: activeProjectLocation?.id });
    useRequestRawRelations({ plId: activeProjectLocation?.id });

    useEffect(() => {
        const lambdaUrl = 'https://api.focustech.xyz/core/v1/pl-relations-dev';
        // activeLocation?.id && yaFunc(lambdaUrl);
    }, [activeLocation?.id]);

    const yaFunc = async (url: string) => {
        const response = await axios({
            method: 'POST',
            url,
            headers: getHeaders(token || ''),
            data: JSON.stringify({
                x_token: token,
                ml_id: activeLocation?.id,
                pl_id: null,
                map_service_url: 'https://map-service.focustech.xyz/',
                admin_url: 'https://admin.focustech.xyz/',
                mode: 'back',
            }),
        });
        console.log('pl-relations lambda >>>', response.data);
    };

    useEffect(() => {
        const plId = params?.plId;

        if (token && plId) {
            dispatch(fetchPlData(PROJECT_LOCATIONS_URL, token, plId));
        }
    }, [token, params?.plId, PROJECT_LOCATIONS_URL]);

    useEffect(() => {
        // locationsList.length === 0 && dispatch(fetchLocations(LOCATIONS_URL, token));
    }, []);

    useEffect(() => {
        let locationId = String(activeLocation?.id);
        versions.length !== 0 && activeLocation?.id && dispatch(fetchPlans(PLANS_URL, token, locationId));
        dispatch(resetEditor());
    }, [PLANS_URL, activeLocation?.id, dispatch, token, versions]);

    const tabs = [
        { key: 'plansList', text: t('Plans list') },
        { key: 'objectsManagement', text: t('Objects management') },
        {
            key: 'locationManagement',
            text: t('Location management'),
        },
    ];

    const pivotItems = tabs.map((item) => {
        return (
            <PivotItem
                key={item.key}
                // onRenderItemLink={() => onRenderItemLink(item.key)}
                itemKey={item.key}
                headerText={t(item.text)}
            />
        );
    });

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            setSelectedTab(() => item.props.itemKey!);
        }
    };

    const onAddNewPlanClick = () => {
        setModalStatus({ show: true });
    };

    const onCreateNewPlanClick = (data: IPlan) => {
        dispatch(storePlanToAddMeasureCut({ planToAddMeasureCut: data }));
        setModalStatus({ show: false });
    };

    const onUploadPDFClick = (data: IUploadPDFArgs) => {
        dispatch(uploadPdfToBack(data));
        dispatch(storePdfModalStatus({ show: false }));
    };

    const storePlanToEditTrans = (planToEdit: IPlan | null) => {
        planToEdit && dispatch(storePlanToEdit({ planToEdit }));
    };

    const toggleAlertTrans = ({ show, text }: { show: boolean; text: string }) => {
        dispatch(toggleAlert({ id: 'plans', show: true, text }));
    };

    const addSegmentEditor = () => {
        if (planToAddMeasureCut?.image) {
            const neededLayers = layers.filter((layer) => layer.id === 'measureCut');
            return (
                <Editor image={planToAddMeasureCut.image} layers={neededLayers} currentPlanData={planToAddMeasureCut} />
            );
        } else return null;
    };

    const plansContextValue = {
        readOnly,
        plansList,
        activeLocation,
        storePlanToEditTrans,
        toggleAlertTrans,
    };

    /**
     * Функция редиректит браузер на геомаппинг.
     * @param args { location: ILocation; plan: IPlan }
     */
    const redirectToGeoMapping = (args: { location: ILocation; plan: IPlan }) => {
        // console.log('>>', document.location.href);

        args.location &&
            window.location.replace(
                `${GEO_URL}?location_id=${args.location.id}&plan_id=${args.plan.planId}&origin=${document.location.href}`,
            );
    };

    const onShowLesingPlansChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setShowLeasingPlans(!!checked);
        },
        [],
    );

    const plansPage = (
        <>
            <StackHorizontal>
                {!readOnly && (
                    <PrimaryButton
                        text={t('Add new plan')}
                        onClick={onAddNewPlanClick}
                        allowDisabledFocus
                        disabled={false}
                    />
                )}
                <Checkbox
                    label={t('Show leasing plans')}
                    checked={showLeasingPlans}
                    onChange={onShowLesingPlansChange}
                />
            </StackHorizontal>

            {plansList && plansList.length !== 0 ? (
                <List
                    showLeasingPlans={showLeasingPlans}
                    onGeoClick={redirectToGeoMapping}
                    onEditPlanClick={onCreateNewPlanClick}
                />
            ) : null}
        </>
    );

    return (
        <PlansContext.Provider value={plansContextValue}>
            {planToAddMeasureCut ? (
                addSegmentEditor()
            ) : (
                <PlansWrapper bgcolor={neutralLighterAlt} color={neutralDark}>
                    <Stack horizontalAlign="end">
                        <StyledPivot
                            selectedKey={selectedTab}
                            // eslint-disable-next-line react/jsx-no-bind
                            onLinkClick={handleLinkClick}
                            headersOnly={true}
                            // getTabId={getTabId}
                        >
                            {pivotItems}
                        </StyledPivot>
                    </Stack>

                    {selectedTab === 'plansList' ? (
                        plansPage
                    ) : selectedTab === 'objectsManagement' ? (
                        <ObjectManagement />
                    ) : selectedTab === 'locationManagement' ? (
                        <LocationManagement />
                    ) : null}
                </PlansWrapper>
            )}

            <Modal modalStatus={modalStatus} title={t('Add new plan')}>
                <AddNewPlan onOKClick={onCreateNewPlanClick} />
            </Modal>
            <Modal modalStatus={pdfModalStatus} title={t('Upload new plan request')}>
                <UploadPdf floor={pdfModalStatus.floor} onOKClick={onUploadPDFClick} />
            </Modal>
        </PlansContext.Provider>
    );
});

export default Plans;
