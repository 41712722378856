import { INodeContextMenu } from '../../../../tools/commonInterfaces';
import { IGeneralSettings, IHotAddObject, IPlan, IVersionsData } from '../../layers.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from './store';
import { commonTools } from '../../layers';

import { ILiftsReducer, ILift } from './interfaces';
import { changeOtherLayers } from './components/core/changeOtherLayers';
import { deleteObjectFromOtherLayers } from './components/core/deleteObjectFromOtherLayers';

export const initialState: ILiftsReducer = {
    layerAlias: 'lifts_layer',
    activeToolId: null,
    selectedObjectId: null,
    selectedPassPointId: null,
    createdAt: undefined,
    objects: [],
    allTheFloors: [],
    plansList: [],
    versionsData: null,
    currentPlanData: null,
    showLabels: false,
    generalSettings: {} as IGeneralSettings,
    anotherLocationNames: { markers: [], names: [] },
    anotherLocationLayers: [],
    contextMenu: { show: false },
    hotAddObject: null,
    objectsTemp: [],
};

export const LiftsReducer = createSlice({
    name: 'LiftsReducer',
    initialState,
    reducers: {
        /**
         * Изменение этажа объекта
         */
        changeObjectFloors: (state, action: PayloadAction<{ checked: boolean; floor: number; front_id: string }>) => {
            const { front_id, floor, checked } = action.payload;
            const { objects, plansList, currentPlanData, allTheFloors, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;
            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    if (item.floors.includes(floor)) {
                        if (!checked) {
                            const lift = { ...item, floors: item.floors.filter((fl) => fl !== floor) };
                            state.anotherLocationLayers = changeOtherLayers({
                                currentPlanData,
                                allTheFloors,
                                lift,
                                plansList,
                                anotherLocationLayers,
                            }) as { [x: string]: any }[];
                            return lift;
                        } else return item;
                    } else {
                        const lift = { ...item, floors: [...item.floors, floor] };
                        state.anotherLocationLayers = changeOtherLayers({
                            currentPlanData,
                            allTheFloors,
                            lift,
                            plansList,
                            anotherLocationLayers,
                        }) as { [x: string]: any }[];
                        return lift;
                    }
                }
            });

            state.objects = newObjects;
            state.objectsTemp = newObjects;
        },

        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string | null>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Сохранение нового объекта в сторе
         */
        storeNewObject: (state, action: PayloadAction<ILift | null>) => {
            const { objects, currentPlanData } = cloneDeep(state);

            if (!currentPlanData || !action.payload) return;

            const currentFloor = currentPlanData.floor;

            const anotherLocationNames = cloneDeep(state.anotherLocationNames);

            const currentMarkers = objects.map((item) => item.marker.split('✚')[0]);
            const currentNames = objects.map((item) => item.name.split('✚')[0]);

            const nextMarkerNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers.map((item) => item.split('✚')[0]), ...currentMarkers],
                'lift',
            );

            const nextNameNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.names.map((item) => item.split('✚')[0]), ...currentNames],
                'Lift',
            );

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:lift${nextMarkerNumber}`;
            const name = 'Lift' + nextNameNumber;
            const front_id = `v${String(activeVersionId)}:lift:${commonTools.generateId()}`;

            const newObject = {
                ...action.payload,
                marker,
                name,
                front_id,
                floors: [currentFloor],
            };
            objects.push(newObject as ILift);
            state.objects = objects;
            state.objectsTemp = objects;
            state.selectedObjectId = front_id;
        },

        /**
         * Выбор объекта
         */
        selectObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload === undefined) {
                state.selectedObjectId = null;
            } else {
                state.selectedObjectId = action.payload;
            }
        },

        /**
         * Удаление объекта
         */
        deleteObject: (state, action: PayloadAction<string | undefined>) => {
            const { objects, anotherLocationLayers } = cloneDeep(state);
            if (action.payload !== undefined) {
                state.objects = objects.filter((item) => item.front_id !== action.payload);
                state.objectsTemp = objects.filter((item) => item.front_id !== action.payload);
                state.anotherLocationLayers = deleteObjectFromOtherLayers(action.payload, anotherLocationLayers) as {
                    [x: string]: any;
                }[];
            }
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ elementId: string; anchorIndex: number }>) => {
            if (action.payload !== undefined) {
                const objects = cloneDeep(state.objects).map((item) => {
                    if (item.front_id !== action.payload.elementId) {
                        return item;
                    } else {
                        const coordinates = cloneDeep(item.geometry.coordinates);
                        coordinates.splice(action.payload.anchorIndex, 1);
                        const result = { ...item, geometry: { ...item.geometry, coordinates } };
                        return result;
                    }
                });
                state.objects = objects;
                state.objectsTemp = objects;
            }
        },

        /**
         * Удаление узелка
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof ILift; newValue: string | boolean | undefined; front_id: string }>,
        ) => {
            const { front_id, key, newValue } = action.payload;
            const { objects, currentPlanData, anotherLocationNames, plansList, anotherLocationLayers, allTheFloors } =
                cloneDeep(state);
            if (!currentPlanData) return;
            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    let floors = item.floors;
                    const lift = { ...item, [key]: newValue, floors };
                    const newAnother = changeOtherLayers({
                        currentPlanData,
                        allTheFloors,
                        lift,
                        plansList,
                        anotherLocationLayers,
                    }) as { [x: string]: any }[];

                    state.anotherLocationLayers = newAnother;
                    return lift;
                }
            });
            state.objects = newObjects;
            state.objectsTemp = newObjects;
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<ILift>>) => {
            state.objects = action.payload || [];
            state.objectsTemp = action.payload || [];
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storePlansList: (state, action: PayloadAction<Array<IPlan>>) => {
            state.plansList = action.payload || [];
            const allTheFloors = action.payload
                .filter((item: IPlan) => item.isActual)
                .sort((a: IPlan, b: IPlan) => Number(a.floor) - Number(b.floor))
                .map((item: IPlan) => item.floor);
            state.allTheFloors = allTheFloors || [];
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Изменение положения узла
         */
        moveAnchor: (
            state,
            action: PayloadAction<{ pointIndex: number; front_id: string; newCoords: Array<number> }>,
        ) => {
            const { currentPlanData, allTheFloors, plansList, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;
            const { pointIndex, front_id, newCoords } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const { geometry } = item;
                    const coordinates = geometry.coordinates.map((point, i) => {
                        if (pointIndex !== i) {
                            return point;
                        } else {
                            return newCoords;
                        }
                    });
                    geometry.coordinates = coordinates;
                    const lift = { ...item, geometry };
                    state.anotherLocationLayers = changeOtherLayers({
                        currentPlanData,
                        allTheFloors,
                        lift,
                        plansList,
                        anotherLocationLayers,
                    }) as { [x: string]: any }[];
                    return lift;
                }
            });
            state.objects = objects;
            state.objectsTemp = objects;
        },

        /**
         * Сохранение в стор всех имен зи других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];
            const names: string[] = [];

            action?.payload.forEach((item: { data?: Array<{ marker: string; name: string; front_id: string }> }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string; name: string; front_id: string }) => {
                    markers.push(`${obj.marker}✚${obj.front_id}`);
                    names.push(`${obj.name}✚${obj.front_id}`);
                });
            });
            const anotherLocationNames = { markers, names };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Сохранение в стор всех слоев текущей локации.
         */
        storeAnotherLocationLayers: (state, action: PayloadAction<Array<object>>) => {
            state.anotherLocationLayers = action.payload;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Добавление узелка.
         */
        addAnchor: (state, action: PayloadAction<{ front_id: string; newPoint: number[] }>) => {
            const { front_id, newPoint } = action.payload;
            const { currentPlanData, allTheFloors, plansList, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;

            const objects = cloneDeep(state.objects).map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const coordinates = cloneDeep(item.geometry.coordinates);
                    let index: null | number = null;
                    coordinates.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, newPoint)) {
                                index = i;
                            }
                        }
                    });
                    if (index !== null) {
                        coordinates.splice(index, 0, newPoint);
                    }
                    const lift = { ...item, geometry: { ...item.geometry, coordinates } };
                    state.anotherLocationLayers = changeOtherLayers({
                        currentPlanData,
                        allTheFloors,
                        lift,
                        plansList,
                        anotherLocationLayers,
                    }) as { [x: string]: any }[];
                    return lift;
                }
            });
            state.objects = objects;
            state.objectsTemp = objects;
        },

        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjectId = null;
            state.selectedPassPointId = null;
        },

        changeShapeCoords: (state, action: PayloadAction) => {
            state.objectsTemp = state.objects;
        },

        removeLastCoords: (state, action: PayloadAction<string>) => {
            const { objects, plansList, currentPlanData, allTheFloors, anotherLocationLayers, objectsTemp } =
                cloneDeep(state);
            if (!currentPlanData) return;
            const index = objects.findIndex((item) => item.front_id === action.payload);
            state.objects = objectsTemp;

            state.anotherLocationLayers = changeOtherLayers({
                currentPlanData,
                allTheFloors,
                lift: objectsTemp[index],
                plansList,
                anotherLocationLayers,
            }) as { [x: string]: any }[];
        },

        changeCoords: (state, action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string }>) => {
            const { coordsS, front_id } = action.payload;
            const { objects, plansList, currentPlanData, allTheFloors, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;
            const index = objects.findIndex((item) => item.front_id === front_id);

            index !== -1 &&
                (objects[index] = {
                    ...objects[index],
                    geometry: {
                        ...objects[index].geometry,
                        coordinates: coordsS!,
                    },
                });

            state.objects = objects;
            state.anotherLocationLayers = changeOtherLayers({
                currentPlanData,
                allTheFloors,
                lift: objects[index],
                plansList,
                anotherLocationLayers,
            }) as { [x: string]: any }[];
        },
    },
});

export const {
    toggleTool,
    storeNewObject,
    selectObject,
    deleteAnchor,
    deleteObject,
    changeObjectFloors,
    changeObjectParams,
    addAnchor,
    toggleContextMenu,
    storeInitialObjects,
    storePlansList,
    storeVersionsData,
    storeInitialCreatedAt,
    storeHotAddObject,
    moveAnchor,
    storeCurrentPlanData,
    storeGeneralSettings,
    toggleShowLabels,
    storeAnotherLocationLayers,
    storeAnotherLocationNames,
    clearSelectedObjects,
    changeShapeCoords,
    removeLastCoords,
    changeCoords,
} = LiftsReducer.actions;

export const reducerValues = (state: RootState) => state.LiftsReducer;

export default LiftsReducer.reducer;
