import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Route, Link, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Locations from './components/Locations/Locations';
import Plans from './components/Plans/Plans';
import Editor from './components/Editor/Editor';
import Spinner from './components/Spinner/Spinner';
import { Alerts } from './components/Alerts/Alerts';
import { generalReducerValues, storeToken, fillBackEnd } from './General.reducer';
import { changePermissions, settingsReducerValues } from './components/Settings/Settings.reducer';
import { plansReducerValues } from './components/Plans/Plans.reducer';
import { locationsReducerValues } from './components/Locations/Locations.reducer';
import { editorReducerValues } from './components/Editor/Editor.reducer';
import { GeneralContext, initialGeneralData } from './General.context';
import { GeneralWrapper, NoPermissions } from './General.styles';
import { IPlan } from './components/Plans/Plans.interfaces';
import 'react-datasheet/lib/react-datasheet.css';

import useUrls from './tools/useUrls';
import useSettings from './tools/useSettings';
import useWindowSize from './tools/useWindowResize';
import allLayers from './components/Layers/layers';
import Modal from './components/Modals/Modals';
import EngineeringModal from './components/Modals/components/EngineeringModal/EngineeringModal';
import { IHosts, IUrls } from './General.interfaces';
import ErrorPage from './components/ErrorPage/ErrorPage';
import './style.scss';
import axios from 'axios';
import { method } from 'lodash';
import TokenExpiration from './components/TokenExpiration/TokenExpiration';
import Projects from './components/Projects/Projects';
import useInitialData from './tools/useInitialData';
import { ILayer } from './components/Layers/layers.interfaces';

function General() {
    const { activeLayerId } = useSelector(editorReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { token, showSpinner, windowSize, urls, backEnd, permissions, current } = useSelector(generalReducerValues);
    const { generalSettings } = useSelector(settingsReducerValues);
    const { planToEdit, planBlueprintScale, imageOffset } = useSelector(plansReducerValues);
    const { activeLocation, activeProjectLocation } = useSelector(locationsReducerValues);
    const [engineeringCount, setEngineeringCount] = useState(0);
    const [engineeringModalStatus, setEngineeringModalStatus] = useState({ show: false });

    const layers = allLayers.filter((layer) => layer.id !== 'measureCut');

    useHotkeys('shift+d+o', () => !generalSettings.readOnly && setEngineeringCount((prev) => prev + 1), [
        generalSettings,
    ]);

    useUrls();
    useSettings();
    useWindowSize();
    useInitialData(layers as ILayer[]);

    useEffect(() => {
        if (engineeringCount > 0) {
            setEngineeringModalStatus({ show: true });
        }
    }, [engineeringCount]);

    useEffect(() => {
        dispatch(changePermissions(permissions));
    }, [permissions]);

    useEffect(() => {
        // planToEdit?.planId && activeLocation?.id && history.push(`/editor/${activeLocation?.id}/${planToEdit.planId}`);
    }, [planToEdit]);

    const generalContextValue = {
        ...initialGeneralData,
        token,
        windowSize,
        generalSettings,
        urls,
        showSpinner,
    };

    let planScale = 1;
    let planWidth = 2000;
    let planHeight = 2000;
    try {
        planScale = planToEdit?.planScale!;
        planWidth = planToEdit?.planWidth!;
        planHeight = planToEdit?.planHeight!;
    } catch (error) {}

    const tempEditorProps = {
        image: planToEdit?.image,
        currentPlanData: {
            ...planToEdit,
            imageOffset,
            planScale: planScale! * planBlueprintScale,
            planWidth: planWidth! * planBlueprintScale,
            planHeight: planHeight! * planBlueprintScale,
        } as IPlan,
        layers,
    };

    const onModalOKClick = (options: IHosts) => {
        localStorage.setItem('hostsUrls', JSON.stringify(options));
        window.location.reload();
    };

    if (current && !generalSettings.displayContent && !showSpinner) {
        return <NoPermissions>{t("You don't have permissions!")}</NoPermissions>;
    }

    const withHeader = (component: ReactNode): ReactNode => {
        return (
            <>
                <Header />
                {component}
            </>
        );
    };

    return (
        <GeneralContext.Provider value={generalContextValue}>
            <TokenExpiration />
            {token !== '' && (
                <GeneralWrapper>
                    <Modal modalStatus={engineeringModalStatus} title={t('Change backend urls')}>
                        <EngineeringModal modalData={backEnd} onOKClick={onModalOKClick} />
                    </Modal>
                    {showSpinner ? <Spinner /> : null}
                    <Alerts />
                    <Routes>
                        <Route path="/" element={withHeader(<Projects />)} />
                        <Route path="/project/:projectId" element={withHeader(<Locations />)} />
                        <Route path="/project/:projectId/pl/:plId" element={withHeader(<Plans />)} />
                        <Route
                            path="/project/:projectId/pl/:plId/plan/:planId"
                            element={withHeader(<Editor {...tempEditorProps} />)}
                        />
                        <Route element={<ErrorPage />} />
                    </Routes>
                </GeneralWrapper>
            )}
        </GeneralContext.Provider>
    );
}

export default General;
