import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { IEscalator } from '../../../../interfaces';
import {
    reducerValues,
    selectObject,
    deleteObject,
    storeInitialObjects,
    storeCurrentPlanData,
    storePlansList,
    storeInitialCreatedAt,
    storeGeneralSettings,
    storeVersionsData,
    toggleShowLabels,
    storeAnotherLocationLayers,
    storeAnotherLocationNames,
    storeHotAddObject,
} from '../../../../reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames } from './ObjectsList.styles';
import { commonTools } from '../../../../../../layers';

/**
 * Компонент списка объектов слоя
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, createdAt, layerAlias, generalSettings, anotherLocationLayers } =
        useSelector(reducerValues);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.revertLayer && dispatch(storeInitialObjects(props.initialData.data));
    }, [props.revertLayer]);

    // useEffect(() => {
    //     props.toggleLayerContextMenu({ layerAlias, contextMenu });
    // }, [contextMenu.show]);

    useEffect(() => {
        props.layerChange({ id: layerAlias, objects, lastCreatedAt: createdAt, selectedObjectId });
    }, [objects, selectedObjectId]);

    useEffect(() => {
        if (!anotherLocationLayers.length) return;
        props.otherLayersChange({ id: layerAlias, anotherLocationLayers, lastCreatedAt: createdAt });
    }, [anotherLocationLayers]);

    useEffect(() => {
        dispatch(storeAnotherLocationNames(props.anotherLocationLayers));
        dispatch(storeAnotherLocationLayers(props.anotherLocationLayers));
    }, [JSON.stringify(props.anotherLocationLayers)]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(storePlansList(props.plansList));
    }, [props.plansList]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(selectObject());
    }, [props.active]);

    useEffect(() => {
        if (!props.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    const { t } = useTranslation();

    const onObjectClick = (id: string) => () => {
        dispatch(selectObject(id));
    };

    const onDeleteIconClick = (id: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(deleteObject(id));
    };

    const objectsList = objects.map((object: IEscalator) => {
        const selected = object.front_id === selectedObjectId;
        return (
            <ObjectWrapper key={object.front_id} selected={selected} onClick={onObjectClick(object.front_id)}>
                {commonTools.getObjectLabelByMode({ objNamingMode: props?.generalSettings?.objNamingMode, object })}
                {!generalSettings.readOnly && (
                    <FontIcon
                        iconName="Cancel"
                        className={classNames.salmon}
                        onClick={onDeleteIconClick(object.front_id)}
                    />
                )}
            </ObjectWrapper>
        );
    });

    return <ObjectsListWrapper>{objectsList}</ObjectsListWrapper>;
};

export default ObjectsList;
