import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    zonesValues,
    selectGroupZones,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeInitialObjects,
    saveNewGroupZones,
    toggleShowLabels,
    selectZone,
    deleteZone,
    deleteGroupZone,
    storeGeneralSettings,
    clearSelectedObjects,
    storeAnotherLocationNames,
    showTable,
    hideTable,
    storeVersionsData,
    storeHotAddObject,
    pasteObjectFromClipboard,
} from '../../../../zones.reducer';
import { useGroupForZones } from './core/useGroupForZones';
import {
    ObjectsListWrapper,
    contentStyles,
    GroupHeaderWrapper,
    ItemColumnWrapper,
    DeleteGroupZonesButtonWrapper,
    classNames,
    ButtonStyleAdd,
} from './ObjectsList.styles';
import { IGroupHeaderPropsCustom, IDetailsGroupRenderPropsCustom, IZone } from '../../../../zones.interfaces';
import { DefaultButton } from '@fluentui/react/lib/Button';
import {
    DetailsList,
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    Modal,
    IDragOptions,
    ContextualMenu,
    IStackTokens,
    IDetailsRowStyles,
    IDetailsListProps,
} from '@fluentui/react';
import { GroupHeader } from '@fluentui/react/lib/GroupedList';
import { buildColumns, DetailsRow, IDetailsRowProps } from '@fluentui/react/lib/DetailsList';
import { IIconProps } from '@fluentui/react';

import { commonTools } from '../../../../../../../../tools/commonTools';
import ContentModal from './componetns/ContentModal';
import { StyledStack } from '../ObjectOptions/ObjectOptions.styles';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { cloneDeep } from 'lodash';

const addIcon: IIconProps = { iconName: 'Add' };
const tableIcon: IIconProps = { iconName: 'Table' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const StackTokens: IStackTokens = {
    childrenGap: 5,
};

/**
 * Список объектов
 * @param props
 * @constructor
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    // const [isShowModalTable, setIsShowModalTable] = useState<boolean>(false);
    const [dataModal, setDataModal] = useState<{
        name: string | undefined;
        marker: string | undefined;
    } | null>(null);
    const { t } = useTranslation();
    const {
        objects,
        createdAt,
        groupFrontId,
        frontIdZonesByFrontIdGroups,
        zoneFrontId,
        layerAlias,
        anotherLocationNames,
        isShowTable,
        objFloorsAndGroupZones,
        groupDataByMarkerGroupGlobal,
        generalSettings,
        versionsData,
        selectedObjects,
    } = useSelector(zonesValues);

    const { items, groups } = useGroupForZones(objects, props?.generalSettings?.objNamingMode);

    useEffect(() => {
        if (props.clipboardData) {
            if (selectedObjects?.zone_marker !== (props.clipboardData.obj as IZone).zone_marker) {
                props.toggleAlertTrans &&
                    props.toggleAlertTrans({
                        text: t('Wrong zone marker selected'),
                        show: true,
                    });
            } else {
                dispatch(pasteObjectFromClipboard(props.clipboardData.obj));
            }
        }
    }, [props.clipboardData]);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.layerChange({ id: layerAlias, objects, lastCreatedAt: createdAt });
    }, [objects]);

    useEffect(() => {
        dispatch(storeAnotherLocationNames(props.anotherLocationLayers));
    }, [props.anotherLocationLayers]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData.planId]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
    }, [props.currentPlanData.floor]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        props?.initialData?.data && props.revertLayer && dispatch(storeInitialObjects(props?.initialData?.data));
    }, [props.revertLayer]);

    useEffect(() => {
        if (!props?.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data || []));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
    }, [props.active]);

    const onClickItemColumn = (groupFrontId?: string, itemFrontId?: string) => () => {
        dispatch(selectZone({ groupFrontId, itemFrontId }));
    };

    const renderItemColumn = (item?: { [x: string]: any }, index?: any, column?: IColumn | undefined) => {
        let statusSelect = item!.front_id === zoneFrontId;

        const onClickItemDelete = () => {
            dispatch(deleteZone({ zoneFrontId: item!.front_id }));
        };

        return (
            <ItemColumnWrapper
                statusSelect={statusSelect}
                key={`ItemColumnWrapper:${index}`}
                onClick={onClickItemColumn(frontIdZonesByFrontIdGroups![item!.front_id], item!.front_id)}
            >
                <span>{item!.labelText}</span>

                {!generalSettings.readOnly && (
                    <FontIcon iconName="Delete" className={classNames.salmon} onClick={onClickItemDelete} />
                )}
            </ItemColumnWrapper>
        );
    };

    const onRenderDetailsHeader = (
        props?: IDetailsHeaderProps,
        _defaultRender?: IRenderFunction<IDetailsHeaderProps>,
    ) => {
        if (props) {
            return null;
        } else {
            return null;
        }
    };

    function myBuildColumns() {
        const columnsToDisplay = ['groupZones'].map((item) => {
            return { 'group zones': 'group zones' };
        });

        const columns = buildColumns(columnsToDisplay);

        return columns;
    }

    const onRenderHeader = (props?: IGroupHeaderPropsCustom): JSX.Element => {
        const onClickHeaderGroup = (): void => {
            dispatch(selectGroupZones({ front_id: props!.group!.front_id }));
        };

        const onClickDeleteGroup = (event: any) => {
            event.stopPropagation();
            dispatch(deleteGroupZone({ front_id: props!.group!.front_id }));
        };

        let statusSelect = groupFrontId === props!.group!.front_id;

        return (
            <GroupHeaderWrapper
                key={`GroupHeaderWrapper:${props!.group!.front_id}`}
                statusSelect={statusSelect}
                onClick={onClickHeaderGroup}
            >
                <GroupHeader {...props} />
                {!generalSettings.readOnly && (
                    <DeleteGroupZonesButtonWrapper>
                        <FontIcon iconName="Delete" className={classNames.salmon} onClick={onClickDeleteGroup} />
                    </DeleteGroupZonesButtonWrapper>
                )}
            </GroupHeaderWrapper>
        );
    };

    const onClickButtonShowModal = () => {
        let currentMarkers: string[] = [];
        let currentNames: string[] = [];

        currentMarkers = objects.map((item) => item.group_marker!);
        currentNames = objects.map((item) => item.group_name!);

        const nextNumber = commonTools.getNextMarkerNumber(
            [...anotherLocationNames.markersGroupZones, ...currentMarkers],
            'Group_Zones_marker',
        );

        const activeVersionId = versionsData?.activeVersionId;
        const marker = `v${String(activeVersionId)}:Group_Zones_marker${nextNumber}`;
        const name = 'Group_Zones' + nextNumber;

        setDataModal({
            name: name,
            marker: marker,
        });
        setIsShowModal(true);
    };

    const showModalTable = () => {
        // setIsShowModalTable(true);
        dispatch(showTable());
    };

    const hideModalTable = () => {
        // setIsShowModalTable(false);
        dispatch(hideTable());
    };

    const onClickButtonHideModal = () => {
        setIsShowModal(false);
    };

    const onChangeMarkerGroupZones = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        setDataModal({
            ...dataModal!,
            marker: commonTools.matchChars(newValue),
        });
    };

    const onChangeNameGroupZones = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        setDataModal({
            ...dataModal!,
            name: newValue,
        });
    };

    const onClickSaveGroupZones = () => {
        dispatch(saveNewGroupZones({ ...dataModal }));
        setIsShowModal(false);
    };

    let objGroupProps: IDetailsGroupRenderPropsCustom = {
        showEmptyGroups: true,
        onRenderHeader: onRenderHeader,
    };

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };

    const stackTokens: IStackTokens = { childrenGap: 10 };

    const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = { backgroundColor: '#edebe9' };

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    return (
        <ObjectsListWrapper>
            <StyledStack tokens={StackTokens}>
                {!generalSettings.readOnly && (
                    <DefaultButton
                        styles={ButtonStyleAdd}
                        text={t('Add new group')}
                        onClick={onClickButtonShowModal}
                        allowDisabledFocus
                        iconProps={addIcon}
                    />
                )}
                <DetailsList
                    items={items}
                    groups={groups}
                    onRenderItemColumn={renderItemColumn}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    columns={myBuildColumns()}
                    selectionMode={0}
                    onRenderRow={_onRenderRow}
                    groupProps={objGroupProps}
                    compact={true}
                />
            </StyledStack>

            <Modal
                isOpen={isShowModal}
                onDismiss={onClickButtonHideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
                dragOptions={dragOptions}
            >
                <ContentModal
                    cancelIcon={cancelIcon}
                    onClickButtonHideModal={onClickButtonHideModal}
                    stackTokens={stackTokens}
                    dataModal={dataModal}
                    onChangeNameGroupZones={onChangeNameGroupZones}
                    onChangeMarkerGroupZones={onChangeMarkerGroupZones}
                    onClickSaveGroupZones={onClickSaveGroupZones}
                    contentType={'newGroupZones'}
                />
            </Modal>

            <Modal
                isOpen={isShowTable}
                onDismiss={hideModalTable}
                isBlocking={false}
                containerClassName={contentStyles.container}
                dragOptions={dragOptions}
            >
                <ContentModal
                    cancelIcon={cancelIcon}
                    onClickButtonHideModal={hideModalTable}
                    stackTokens={stackTokens}
                    dataModal={dataModal}
                    groupDataByMarkerGroup={groupDataByMarkerGroupGlobal}
                    contentType={'dataGlobalGroupZones'}
                    objFloorsAndGroupZones={objFloorsAndGroupZones}
                />
            </Modal>
        </ObjectsListWrapper>
    );
};

export default ObjectsList;
