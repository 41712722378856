import { IContextMenu, INodeContextMenu } from './../../../../tools/commonInterfaces';
import { IExtendedPlan, IGeneralSettings, IVersionsData } from './../../layers.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from './passWays.store';
import { commonTools } from '../../layers';
import { IPlan } from '../../layers.interfaces';

import { IPassWaysReducer, IPassWay, IHotAddObject, IObjects } from './passWays.interfaces';
import { sizes } from '../../../../constants/sizes';

export const initialState: IPassWaysReducer = {
    layerAlias: 'pass_ways_layer',
    activeToolId: null,
    selectedObjectId: null,
    selectedObjectMarker: null,

    createdAt: undefined,
    versionsData: null,
    objects: [],
    currentPlanData: null,
    showLabels: false,
    generalSettings: {} as IGeneralSettings,
    anotherLocationNames: { markers: [], names: [] },
    contextMenu: { show: false },
    hotAddObject: null,
};

export const PassWaysReducer = createSlice({
    name: 'PassWaysReducer',
    initialState,
    reducers: {
        pasteObjectFromClipboard: (state, action: PayloadAction<IPassWay>) => {
            const { objects, versionsData, selectedObjectId } = cloneDeep(state);
            const selectedObject = objects.filter((item) => item.id === selectedObjectId)[0];

            if (selectedObject?.marker === action.payload.marker) {
                const id = `v${String(versionsData?.activeVersionId)}:passway:${commonTools.generateId()}`;
                const newObjects = objects.filter((item) => item.id !== selectedObjectId);
                const newObject = cloneDeep({ ...action.payload, id });
                commonTools.shiftCoords(newObject, [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT]);
                newObjects.push(newObject);
                state.objects = newObjects;
                state.selectedObjectId = id;
                state.selectedObjectMarker = action.payload.marker;
            } else {
                console.log('WRONG SELECTED PASSWAY');
            }
        },

        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string | null>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Сохранение нового объекта в сторе
         */
        storeNewObject: (state, action: PayloadAction<IPassWay | null>) => {
            const objects = cloneDeep(state.objects);
            const anotherLocationNames = cloneDeep(state.anotherLocationNames);

            const currentMarkers = objects.map((item) => item.marker);
            const currentNames = objects.map((item) => item.name);

            const nextNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers, ...currentMarkers],
                'passway',
            );

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:passway${nextNumber}`;
            const name = 'PassWay' + nextNumber;
            const id = `v${String(activeVersionId)}:passway:${commonTools.generateId()}`;

            const newObject = { ...action.payload, marker, name, id };
            objects.push(newObject as IPassWay);
            state.objects = objects;
            state.selectedObjectId = id;
            state.selectedObjectMarker = marker;
        },

        /**
         * Выбор объекта
         */
        selectObject: (state, action: PayloadAction<string | undefined>) => {
            const objects = cloneDeep(state.objects);
            const selectedObjectMarker = objects.find((item) => item.id === action.payload)?.marker;
            if (action.payload === undefined) {
                state.selectedObjectId = null;
                state.selectedObjectMarker = null;
            } else {
                state.selectedObjectId = action.payload;
                state.selectedObjectMarker = selectedObjectMarker || null;
            }
        },

        /**
         * Удаление объекта
         */
        deleteObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload !== undefined) {
                const objects = cloneDeep(state.objects);
                state.objects = objects.filter((item) => item.id !== action.payload);
            }
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ elementId: string; anchorIndex: number }>) => {
            if (action.payload !== undefined) {
                const objects = cloneDeep(state.objects).map((item) => {
                    if (item.id !== action.payload.elementId) {
                        return item;
                    } else {
                        const coordinates = cloneDeep(item.passLine.coordinates);
                        coordinates.splice(action.payload.anchorIndex, 1);
                        const result = { ...item, passLine: { ...item.passLine, coordinates } };
                        return result;
                    }
                });
                state.objects = objects;
            }
        },

        /**
         * Удаление узелка
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof IPassWay; newValue: string | undefined; id: string }>,
        ) => {
            const { id, key, newValue } = action.payload;
            const objects = cloneDeep(state.objects);
            const newObjects = objects.map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    let value;
                    switch (key) {
                        case 'connectivityMargin':
                            value = Number(newValue);
                            break;
                        case 'passMargin':
                            value = Number(newValue);
                            break;
                        default:
                            value = newValue;
                            break;
                    }
                    return { ...item, [key]: value };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IPassWay>>) => {
            state.objects = action.payload || [];
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Изменение положения узла
         */
        moveAnchor: (state, action: PayloadAction<{ pointIndex: number; id: string; newCoords: Array<number> }>) => {
            const { pointIndex, id, newCoords } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    const { passLine } = item;
                    const coordinates = passLine.coordinates.map((point, i) => {
                        if (pointIndex !== i) {
                            return point;
                        } else {
                            return newCoords;
                        }
                    });
                    passLine.coordinates = coordinates;
                    return { ...item, passLine };
                }
            });
            state.objects = objects;
        },

        /**
         * Реверс объекта
         */
        reversePassWay: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    const { passLine } = item;
                    const coordinates = passLine.coordinates.reverse();
                    passLine.coordinates = coordinates;
                    return { ...item, passLine };
                }
            });
            state.objects = objects;
        },

        /**
         * Сохранение в стор всех имен зи других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];
            const names: string[] = [];

            action?.payload.forEach((item: { data?: Array<{ marker: string; name: string }> }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string; name: string }) => {
                    markers.push(obj.marker);
                    names.push(obj.name);
                });
            });
            const anotherLocationNames = { markers, names };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Добавление узелка.
         */
        addAnchor: (state, action: PayloadAction<{ id: string; newPoint: number[] }>) => {
            const { id, newPoint } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    const coordinates = cloneDeep(item.passLine.coordinates);
                    let index: null | number = null;
                    coordinates.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, newPoint)) {
                                index = i;
                            }
                        }
                    });
                    if (index !== null) {
                        coordinates.splice(index, 0, newPoint);
                    }
                    const result = { ...item, passLine: { ...item.passLine, coordinates } };
                    return result;
                }
            });
            state.objects = objects;
        },

        /**
         * Изменение координат выделеного объекта
         * @param state
         * @param action
         */
        changeCoords: (state, action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string | null }>) => {
            const { coordsS, front_id } = action.payload;
            const objects = cloneDeep(state.objects);

            const index = objects.findIndex((item) => item.id === front_id);
            index !== -1 &&
                (objects[index] = {
                    ...objects[index],
                    passLine: {
                        ...objects[index].passLine,
                        coordinates: coordsS!,
                    },
                });

            state.objects = objects;
        },

        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjectId = null;
        },

        changeCoordsFullLayer: (state, action: PayloadAction<IObjects>) => {
            state.objects = action.payload;
        },
    },
});

export const {
    toggleTool,
    storeNewObject,
    selectObject,
    deleteAnchor,
    deleteObject,
    changeObjectParams,
    addAnchor,
    toggleContextMenu,
    storeInitialObjects,
    storeInitialCreatedAt,
    moveAnchor,
    pasteObjectFromClipboard,
    storeCurrentPlanData,
    storeVersionsData,
    reversePassWay,
    storeGeneralSettings,
    toggleShowLabels,
    storeAnotherLocationNames,
    storeHotAddObject,
    changeCoords,
    clearSelectedObjects,
    changeCoordsFullLayer,
} = PassWaysReducer.actions;

export const passWaysReducerValues = (state: RootState) => state.PassWaysReducer;

export default PassWaysReducer.reducer;
