import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../../Accordion.styles.scss';
import { IAccordionItemProps } from './AccordionItem.interfaces';
import Eye from '../../../Eye/Eye';
import { Container, HeaderWrapper, IconsWrapper, Title } from './AccordionItem.styles';
import SaveIcon from './components/SaveIcon/SaveIcon';
import ShowLabelIcon from './components/ShowLabelIcon/ShowLabelIcon';
import { theme } from '../../../../constants/theme';
import RevertIcon from './components/RevertIcon/RevertIcon';
import { GeneralContext } from '../../../../General.context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipHost } from '@fluentui/react';
import PasteIcon from './components/PasteIcon/PasteIcon';
import { EditorContext } from '../../../Editor/Editor.context';
import { layersAllowToPaste } from '../../../../constants/layersAllowToPaste';

/**
 * Компонент элемента аккордеона.
 * @param {JSX.Element} objects - компонент контекста слоя.
 * @param {string} title - название слоя.
 * @param {string} layerId - идентификатор слоя.
 * @param {boolean} showLabels - флаг отображения меток.
 * @param {boolean} showSaveIcon - флаг отображения дискетки.
 * @param {boolean} active - флаг является ли слой активным.
 * @param {boolean} visible - флаг является ли слой видимым.
 * @param {(title: string) => void} selectLayer - callback выбора слоя.
 * @param {((title: string) => void) | undefined} onEyeClick - callback переключения видимости слоя.
 * @param {((args: ISaveLayerToServerArgs) => void) | undefined} saveLayerToServer - callback запись измененного слоя на сервер.
 * @param {((args: string) => void) | undefined} onShowLabelIconClick - callback переключения отображения меток.
 * @param {boolean} hasObjects - флаг имеет ли слой объекты.
 */

const AccordionItem = ({
    objects,
    title,
    layerId,
    showLabels,
    showSaveIcon,
    showRevertIcon,
    active,
    visible,
    selectLayer,
    onEyeClick,
    saveLayerToServer,
    onPasteIconClick,
    hasObjects,
    revertLayer,
    activeLayerId,
    onShowLabelIconClick,
    ...props
}: IAccordionItemProps) => {
    const [opened, setOpened] = useState(false);
    const [canPaste, setCanPaste] = useState(false);
    const { showSpinner, generalSettings } = useContext(GeneralContext);
    const { objectFromDiff } = useContext(EditorContext);
    const { t } = useTranslation();

    useEffect(() => {
        setOpened(() => active);
    }, [active]);

    useEffect(() => {
        if (layerId === objectFromDiff?.layer_type) {
            !canPaste && setCanPaste(() => true);
        } else {
            canPaste && setCanPaste(() => false);
        }
    }, [objectFromDiff]);

    const onSaveIconClick = (layerId: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        saveLayerToServer && saveLayerToServer({ id: layerId });
    };

    const onRevertIconClick = (id: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        revertLayer && revertLayer({ id });
    };

    const onShowLabelClick = (layerId: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        onShowLabelIconClick && onShowLabelIconClick(layerId);
    };

    const onPasteClick = useCallback(
        (e: React.SyntheticEvent) => {
            if (!canPaste) return;
            e.stopPropagation();
            onPasteIconClick && onPasteIconClick(objectFromDiff);
        },
        [canPaste, objectFromDiff, onPasteIconClick],
    );

    const onHeadWrapperClick = (layerId: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        selectLayer && selectLayer(layerId);
    };

    return useMemo(() => {
        return (
            <div
                {...{
                    className: `accordion-item, ${opened && 'accordion-item--opened'}`,
                }}
            >
                <div
                    {...{
                        className: 'accordion-item__line',
                    }}
                >
                    <HeaderWrapper onClick={onHeadWrapperClick(layerId)}>
                        <Eye eyeVisible={visible} id={layerId} onEyeClick={onEyeClick} />
                        <Title opened={opened} hasObjects={hasObjects}>
                            {t(title)}
                        </Title>
                    </HeaderWrapper>
                    <IconsWrapper>
                        {showSaveIcon && !showSpinner && (
                            <Container onClick={onSaveIconClick(layerId)}>
                                <TooltipHost content={t('Save to server (Shift + S)')}>
                                    <SaveIcon />
                                </TooltipHost>
                            </Container>
                        )}
                        {showRevertIcon && !showSpinner && (
                            <Container order={4} onClick={onRevertIconClick(layerId)}>
                                <TooltipHost content={t('Undo changes (Shift + R)')}>
                                    <RevertIcon />
                                </TooltipHost>
                            </Container>
                        )}
                        {!generalSettings?.readOnly && layersAllowToPaste.includes(layerId) && (
                            <Container onClick={onPasteClick}>
                                <PasteIcon showLabels={canPaste} color={theme.palette.neutralPrimaryAlt} />
                            </Container>
                        )}
                        <Container onClick={onShowLabelClick(layerId)}>
                            <ShowLabelIcon showLabels={showLabels} color={theme.palette.neutralPrimaryAlt} />
                        </Container>
                    </IconsWrapper>
                    {/* <span {...{ className: 'accordion-item__icon' }} /> */}
                </div>
                <div {...{ className: 'accordion-item__inner' }}>
                    <div {...{ className: 'accordion-item__content' }}>
                        <div {...{ className: 'accordion-item__objects' }}>{objects}</div>
                    </div>
                </div>
            </div>
        );
    }, [
        JSON.stringify(objects.props),
        // JSON.stringify(objects.props?.initialData?.data),
        layerId,
        opened,
        showLabels,
        showSaveIcon,
        showSpinner,
        active,
        visible,
        hasObjects,
        activeLayerId,
        canPaste,
        onPasteClick,
        generalSettings,
    ]);
};

export default AccordionItem;
